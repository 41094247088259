import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import transactionsAPI from "@shared/apis/transactions";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {createSyncedActionCreators, ensureNotBrowser} from "@shared/lib/misc";
import {getCbTxStateAdapter} from "@shared/state/entity-adapters";
import {setAutoFreeze} from "immer";

import type {EntityId, PayloadAction} from "@reduxjs/toolkit";
import type {MonthlyCacheAndCbTxDiff} from "@shared/data-functions/cache/cache-utilities";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {CbTx, TemplateOptions} from "@shared/types/db";

setAutoFreeze(false);

export const api = {
  fetchAllCbTxTransactions: createAsyncThunk(
    "transactions/fetchAllCbTxTransactions",
    transactionsAPI.fetchAllCbTxTransactions,
  ),
  fetchMonthlyCache: createAsyncThunk("transactions/fetchMonthlyCache", transactionsAPI.fetchMonthlyCache),
  fetchTxForDisplay: createAsyncThunk("transactions/fetchTxForDisplay", transactionsAPI.fetchTxForDisplay),
  upsert: createAsyncThunk("transactions/api/upsert", transactionsAPI.upsert),
  delete: createAsyncThunk("transactions/api/delete", transactionsAPI.deleteCbTx),
};

export const getSlice = () => {
  const cbTxStateAdapter = getCbTxStateAdapter();
  return createSlice({
    name: "cbTx",
    initialState: {
      ...cbTxStateAdapter.getInitialState(),
    },
    reducers: {
      deleteCbTxLocal(state, action: PayloadAction<string[]>) {
        ensureNotBrowser();
        cbTxStateAdapter.removeMany(state, action.payload);
      },
      triggerAlgoFromListener(
        state,
        action: PayloadAction<{
          datasourceDiff: DatasourceDiff;
          scenarioIdsImpacted: EntityId[];
          deletedDatasourceIds: EntityId[];
          optimisticDiff?: MonthlyCacheAndCbTxDiff;
          execId: string;
          sendOptimisticUpdate?: boolean;
          originalTemplateOptions?: Record<EntityId, TemplateOptions>;
          disableAddingPreviousDependencies?: boolean;
          isGlobalRecalc?: boolean;
        }>,
      ) {
        // Nothing in reducer, logic is in listener
      },
      deleteMatchingCbTxLocal(state, action: PayloadAction<{datasourceIds: EntityId[]}>) {
        time("deleteMatchingCbTxLocalListener", "Completed execution");
        ensureNotBrowser();
        const ids: string[] = [];
        for (const dsId of action.payload.datasourceIds) {
          ids.push(...(state.idsByDsId[dsId] ?? []));
        }
        if (ids.length) {
          cbTxStateAdapter.removeMany(state, ids);
        }
        timeEnd("deleteMatchingCbTxLocalListener", "Completed execution");
        log(`deleteMatchingCbTxLocalListener`, `Removed [${ids.length}] matching CbTx`);
      },
      upsertCbTx(state, action: PayloadAction<CbTx[]>) {
        ensureNotBrowser();
        cbTxStateAdapter.upsertMany(state, action.payload);
      },
      upsertCbTxLocal(state, action: PayloadAction<CbTx[]>) {
        ensureNotBrowser();
        cbTxStateAdapter.upsertMany(state, action.payload);
      },
      setAllCbTxLocal(state, action: PayloadAction<CbTx[]>) {
        ensureNotBrowser();
        cbTxStateAdapter.setAll(state, action.payload);
      },
      removeCbTx(state, action: PayloadAction<string[]>) {
        cbTxStateAdapter.removeMany(state, action.payload);
      },
      applyChangesFromBackend(state, action: PayloadAction<{upserts: CbTx[]; deletes: string[]; recalc: boolean}>) {
        ensureNotBrowser();
      },
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchAllCbTxTransactions.fulfilled, (state, action) => {
        ensureNotBrowser();

        time("InitialLoad", `cbTx entityAdapter initial setAll`);
        cbTxStateAdapter.setAll(state, action.payload);
        timeEnd("InitialLoad", `cbTx entityAdapter initial setAll`);
      });
    },
  });
};

const _slice = getSlice();

export const {deleteCbTxLocal, upsertCbTxLocal, setAllCbTxLocal, applyChangesFromBackend, triggerAlgoFromListener} =
  _slice.actions;

export const {upsertCbTx, removeCbTx, deleteMatchingCbTxLocal} = createSyncedActionCreators(_slice.actions);
export type TxItemsActions = typeof _slice.actions;
