import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import RightSidebar from "@components/RightSidebar";
import RoundButton from "@components/RoundButton";
import RoundIcon from "@components/RoundIcon";
import SimpleTooltip from "@components/Tooltip/Simple";
import {selectDepartmentEntities} from "@state/departments/slice";
import {selectVersionLocked} from "@state/global/slice";
import {selectAllVendorsById} from "@state/integrations/slice";
import {projKey, projKeyOmitNulls} from "@state/utils";
import {useEffect, useState} from "react";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import {getDatasourceDiff} from "@shared/lib/datasource-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {AccountTemplateRow} from "@shared/types/db";
import {removeCbTx} from "@state/cb-tx/slice";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {removeRow, upsertRows} from "@state/template-rows/slice";
import {selectTemplateEntities} from "@state/templates/slice";
import {selectSelectedRow, selectors} from "../state/selectors";
import {updateTemplatesViewState} from "../state/slice";
import SidebarTabs from "./SidebarTabs";

export default function TemplatesSidebar() {
  const {column, rowId, departmentId, vendor: vendorId} = useAppSelector(selectors.activeCell);
  const selectedRow = useAppSelector(selectSelectedRow);
  const selectedTemplateId = useAppSelector(selectors.templateId);
  const templatesById = useAppSelector(selectTemplateEntities);
  const dispatch = useAppDispatch();
  const isVersionLocked = useAppSelector(selectVersionLocked);
  const vendorsMapping = useAppSelector(selectAllVendorsById);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const [expanded, setExpanded] = useState(false);
  const [tab, setTab] = useState<string>("");

  const template = templatesById[selectedTemplateId ?? ""];

  useEffect(() => {
    setExpanded(false);
  }, [rowId]);

  const handleDelete = () => {
    if (rowId) {
      dispatch(
        askForConfirmation({
          preset: "delete",
          text: `Are you sure you want to delete the row <b style="white-space: nowrap">${
            vendor?.display_name ?? selectedRow?.display_name
          }</b>?<br /><br />All of the transactions and forecasts belonging to this row will be deleted.`,
          onConfirm: () => {
            const state = store.getState();
            dispatch(
              updateTemplatesViewState({
                activeCell: {rowId: null, column: null},
                caller: "TemplatesSidebar->handleDelete",
              }),
            );
            const scenarioIds = state.scenarios.ids;

            if (vendor?.source === "custom" && selectedRow?.type === "account" && departmentId) {
              const newOptionsWithoutVendor: AccountTemplateRow["options"] = {
                ...selectedRow.options,
                customVendors: {
                  ...selectedRow.options.customVendors,
                  [departmentId]:
                    selectedRow.options.customVendors?.[departmentId].filter(({name}) => name !== vendor.id) ?? [],
                },
              };
              const newRow = {...selectedRow, options: newOptionsWithoutVendor};
              dispatch(upsertRows([newRow]));

              for (const scenarioId of scenarioIds) {
                const rowDatasourceIds =
                  state.datasources.idsByProjKey[projKeyOmitNulls(rowId, scenarioId, departmentId, vendorId)];
                const datasources = mapEntitiesToIds(state.datasources.entities, rowDatasourceIds);
                const datasourceDiff = getDatasourceDiff(
                  datasources,
                  datasources.filter((ds) => ds.type !== "formula"),
                  state,
                );

                dispatch(
                  applyDatasourceChanges({datasourceDiff, reason: `Deleted custom vendor ${vendor?.display_name}`}),
                );
              }
            } else {
              const cbTxIdsToDelete: string[] = [];
              for (const scenarioId of scenarioIds) {
                cbTxIdsToDelete.push(...(state.cbTx.idsByRowId[projKey(rowId, scenarioId)] ?? []));
              }
              if (cbTxIdsToDelete) dispatch(removeCbTx(cbTxIdsToDelete));
              dispatch(removeRow(rowId));
            }
            logUserEvent("templates-sidebar-delete-row", {rowId});
            setTab("");
          },
        }),
      );
    }
  };

  const onClose = () => {
    dispatch(
      updateTemplatesViewState({
        activeCell: {rowId: null, column: null},
        caller: "TemplatesSidebar->onClose",
      }),
    );
    setExpanded(false);
  };

  const titleTags: React.ReactNode[] = [];
  let title = selectedRow?.display_name || "Untitled Row";
  if (departmentId) {
    const department = departmentEntities[departmentId];
    titleTags.push(
      <SimpleTooltip key="department" text={department?.display_name ?? ""} delay={0}>
        <RoundIcon mainColor="blue" icon="department" iconColor="tertiaryBlue" />
      </SimpleTooltip>,
    );
    title = department?.display_name ?? "Unknown Department";
  }

  const vendor = vendorsMapping[vendorId ?? ""];
  if (vendorId) {
    const vendorName = vendorId === "no_vendor" ? "No Vendor" : vendor?.display_name ?? "Unknown Vendor";
    titleTags.push(
      <SimpleTooltip key="vendor" text={vendorName}>
        <RoundIcon mainColor="blue" icon="vendor" iconColor="tertiaryBlue" />
      </SimpleTooltip>,
    );
    title = vendorName;
  }

  const isDeletable = template?.type === "generic" || vendor?.source === "custom";

  return !!selectedRow && !column ? (
    <RightSidebar
      onClose={onClose}
      title={title}
      titleTags={titleTags.length ? titleTags : undefined}
      expandable={tab === "data"}
      expandToFullWidth
      onExpandChange={setExpanded}
      topRightButtons={
        isDeletable
          ? [
              <SimpleTooltip key="delete" text="Delete Row" placement="left">
                <RoundButton enableCssStates icon="trash" onClick={handleDelete} />
              </SimpleTooltip>,
            ]
          : []
      }
    >
      <SidebarTabs expanded={expanded} onTabChange={setTab} />
    </RightSidebar>
  ) : null;
}
