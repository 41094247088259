import {useAppSelector} from "@app/hooks";
import Dropdown from "@components/Dropdown/DropdownV2";
import {itemIsNotFalsy} from "@shared/lib/misc";
import {selectTemplateRowEntities} from "@shared/state/template-rows/slice";
import {selectTemplateEntities} from "@shared/state/templates/slice";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {DropdownItem, DropdownWithoutTriggerProps} from "@components/Dropdown/DropdownV2";
import type {TemplateOrdering, TemplateRow} from "@shared/types/db";

export interface DatasourceSelectorProps extends Partial<DropdownWithoutTriggerProps<DropdownItem>> {
  onDatasourceSelect?: (row: TemplateRow | null) => void;
  placeholder?: string;
  disabled?: boolean;
  selectedRowName?: string;
  allowEmpty?: boolean;
  filter?: (item: TemplateRow) => boolean;
  tabIndex?: number;
}

const templateOrder = ["profit_and_loss", "balance_sheet", "cash_flow_statement"];

export default function DatasourceSelector({
  onDatasourceSelect,
  selectedKey,
  selectedRowName,
  placeholder,
  disabled,
  allowEmpty,
  filter,
  ...dropdownProps
}: DatasourceSelectorProps) {
  const styles = useStyles();
  const classes = clsx(styles.datasourceSelectorMain);

  const allTemplateEntities = useAppSelector(selectTemplateEntities);
  const allRows = useAppSelector(selectTemplateRowEntities) as Record<string, TemplateRow>;
  const allRowIdsByName = useAppSelector((state) => state.templateRows.idsByName);

  const items: DropdownItem[] = [];

  if (!selectedKey && !!selectedRowName) selectedKey = allRowIdsByName[selectedRowName] || "";

  const rowIdsAdded = new Set<string>();

  const allTemplates = Object.values(allTemplateEntities).filter(itemIsNotFalsy);
  // Order templates by the order in templateOrder, and then alphabetically by display_name
  const sortedTemplates = templateOrder
    .map((templateName) => allTemplates.find((t) => t?.name === templateName))
    .filter(itemIsNotFalsy);
  sortedTemplates.push(
    ...allTemplates
      .filter((t) => !templateOrder.includes(t.name))
      .toSorted((a, b) => a.display_name.localeCompare(b.display_name)),
  );

  function recursivelyAddRowsInOrder(templateId: string, ordering: TemplateOrdering[], indentation: number = 0) {
    for (const rowOrdering of ordering) {
      const row = allRows[rowOrdering.rowId];
      if (!row || rowIdsAdded.has(row.id) || !row.display_name) continue;
      if (filter && !filter(row)) continue;
      items.push({
        key: rowOrdering.rowId,
        value: row.display_name,
        selected: rowOrdering.rowId === selectedKey,
        rightText: allTemplateEntities[templateId]?.display_name,
        indentation,
      });
      rowIdsAdded.add(rowOrdering.rowId);
      if (rowOrdering.children?.length) recursivelyAddRowsInOrder(templateId, rowOrdering.children, indentation + 1);
    }
  }

  for (const template of sortedTemplates) {
    if (!template) continue;
    recursivelyAddRowsInOrder(template.id, template.ordering);
  }

  const handleSelect = (item: DropdownItem) => {
    if (onDatasourceSelect) onDatasourceSelect(!!item.key ? (allRows[item.key || ""] as TemplateRow) : null);
  };

  let rightText = "";
  if (selectedKey) {
    const template = allTemplateEntities[allRows[selectedKey]?.template_id || ""];
    if (template) rightText = template.display_name;
  }

  if (allowEmpty) items.unshift({key: null, value: placeholder || "Empty", selected: selectedKey === null});

  const errored = dropdownProps.errored || (!!selectedKey && !allRows[selectedKey]);
  // Debug why it's errors if it is
  if (errored) {
    console.error("DatasourceSelector errored", selectedKey, allRows[selectedKey]);
  }

  return (
    <div className={classes}>
      <Dropdown
        disabled={disabled}
        {...dropdownProps}
        errored={dropdownProps.errored || (!!selectedKey && !allRows[selectedKey])}
        items={items}
        onSelect={handleSelect}
        text={
          selectedKey || allowEmpty
            ? !selectedKey
              ? items[0].value.toString()
              : allRows[allRowIdsByName[selectedKey] || ""]?.display_name
            : placeholder || "Select a Data Source..."
        }
        buttonFill
        buttonRightText={selectedKey ? rightText : ""}
        showSearch
      />
    </div>
  );
}
