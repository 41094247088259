import id from "@shared/lib/id";
import {requestCompleted, requestFailed, requestStarted, selectVersionLocked} from "@state/global/slice";
import {isNode} from "browser-or-node";

import {log} from "./debug-provider";
import {sleep} from "./misc";

import type {SerializedError} from "@reduxjs/toolkit";
import type {ApiEndpointContractBase} from "@shared/apis/contracts";
import type {ServerThunkExtra} from "@shared/types/server/shared";
import type {RootState, ThunkPayloadCreator} from "@state/store";

const API_URL = "/api";

const requestWhitelistInVersion = [
  {
    method: "delete",
    path: "session",
  },
];

export type ThunkExtraArg = ({isNode: true} & ServerThunkExtra) | {isNode: false};
export type ThunkAPI = Omit<Parameters<ThunkPayloadCreator>[1], "extra"> & {
  extra: ThunkExtraArg;
};
export type BasicThunkAPI = Omit<Parameters<ThunkPayloadCreator>[1], "getState"> & {getState: () => RootState};

type HttpMethod = "get" | "post" | "put" | "patch" | "delete" | "head";
type QueryParams = {[key: string]: string | number | boolean | undefined};

const PORT: number = isNode && !!process?.env.PORT ? parseInt(process.env.PORT, 10) : 3100;

const serializedPendingQueries: Record<string, Promise<any>> = {};

const serializeQuery = (path: string, payload: any, queryParams: QueryParams) =>
  `${path}-${JSON.stringify(payload)}-${JSON.stringify(queryParams)}`;

let sessionFetchRetries = 0;
/**
 * @template T
 * @param {string} path - The path to fetch from, which will be prefixed with API_URL
 * @param {[key: string]: string | number} [queryParams] - Optional object containing the query parameters
 * @returns {Promise<T>} The response from the server, formatted from JSON
 */
async function fetchFromAPI<T>(
  thunkAPI: ThunkAPI,
  method: HttpMethod,
  path: string,
  payload: any,
  queryParams?: QueryParams,
): Promise<T> {
  const options: any = {
    method,
    headers: {
      Accept: "application/json",
    },
  };
  if (!thunkAPI.extra.isNode) {
    const state = thunkAPI.getState() as RootState;
    if (
      !["head", "get"].includes(method) &&
      selectVersionLocked(state) &&
      !requestWhitelistInVersion.some((item) => item.path === path && item.method === method)
    ) {
      return Promise.reject({
        code: "403",
        name: "FORBIDDEN",
        message: `API call rejected with 403 "FORBIDDEN" - version is locked`,
      } as SerializedError);
    }
    try {
      options.headers["cb-company-id"] = state.session.user?.company_id;
      options.headers["cb-last-full-refresh-time"] = state.global.lastFullRefresh;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  let fullUrl = `${API_URL}/${path}`;
  if (thunkAPI.extra.isNode) {
    const prefix = `http://localhost:${PORT}`;
    fullUrl = `${prefix}${fullUrl}`;
    options.json = false;
  }

  if (queryParams) {
    const pairs: string[] = [];
    for (const [key, value] of Object.entries(queryParams)) {
      if (typeof value === "undefined") continue;
      pairs.push(`${key}=${value}`);
    }
    if (pairs.length) fullUrl += `?${pairs.join("&")}`;
  }

  let parsed: any;
  if (!thunkAPI.extra.isNode) {
    const version = (thunkAPI.getState() as RootState).global.version;
    if (version) options.headers["X-DATA-VERSION"] = version;

    if (typeof payload !== "undefined" && payload !== null && ["put", "post", "delete"].includes(method)) {
      options.body = JSON.stringify(payload);
      options.headers["Content-Type"] = "application/json";
    }

    // @ts-ignore
    let promise: Promise<Response>;
    let serializedQuery: null | string = null;
    if (method === "get") {
      serializedQuery ??= serializeQuery(path, payload, queryParams || {});
      if (typeof serializedPendingQueries[serializedQuery] !== "undefined") {
        promise = serializedPendingQueries[serializedQuery] as Promise<Response>;
      } else {
        promise = fetch(fullUrl, options);
        serializedPendingQueries[serializedQuery] = promise;
      }
    } else {
      promise = fetch(fullUrl, options);
    }

    let res = await promise;

    if (method === "get") {
      serializedQuery ??= serializeQuery(path, payload, queryParams || {});

      if (!!serializedPendingQueries[serializedQuery]) {
        delete serializedPendingQueries[serializedQuery];
      }
    }

    let text = await res.text();

    // Dev utility to retry failed session fetch after a server restart
    if (path === "session" && method === "get" && res.status === 500) {
      while (res.status === 500 && sessionFetchRetries < 8) {
        await sleep(1000);
        sessionFetchRetries++;
        log("Fetch", `Retrying session fetch (${sessionFetchRetries} / 8)...`);
        // @ts-ignore
        res = await fetch(fullUrl, options);
        text = await res.text();
        if (res.status !== 500) log("Fetch", `Successfully fetched session after ${sessionFetchRetries} retries.`);
      }
    }

    if (!text && res.status !== 204) {
      return Promise.reject({
        code: res.status.toString(),
        name: "EMPTY_RESPONSE",
        message: `API call failed with status code ${res.status} and error code "EMPTY_RESPONSE".`,
      } as SerializedError);
    }

    parsed = text ? JSON.parse(text) : {};
  } else {
    const routeResolver = thunkAPI.extra.getRouteResolver(method.toLowerCase(), path);
    if (routeResolver) {
      // @ts-ignore
      const result = await routeResolver.resolver({
        query: queryParams || {},
        params: routeResolver.params,
        body: payload,
        ctx: thunkAPI.extra.ctx,
        sendError: (errorCode) => Promise.reject(),
      });
      parsed = {statusCode: result[0], data: result[1], timestamp: Date.now()};
    }
  }

  if (parsed?.statusCode >= 400) {
    return Promise.reject({
      code: parsed.statusCode,
      error: parsed.error,
      message: `API call failed with status code ${parsed.statusCode} and error code "${parsed.error}".`,
    } as SerializedError);
  }

  return parsed.data;
}

function methodShortcut<M extends "get" | "post" | "put" | "patch" | "delete">(
  method: M,
): M extends "get"
  ? <T>(thunkAPI: ThunkAPI, path: string, queryParams?: {[key: string]: string | number | boolean}) => Promise<T>
  : <T>(
      thunkAPI: ThunkAPI,
      path: string,
      payload?: any,
      queryParams?: {[key: string]: string | number | boolean},
    ) => Promise<T> {
  if (method === "get") {
    return async <T>(thunkAPI: ThunkAPI, path: string, queryParams?: {[key: string]: string | number | boolean}) =>
      fetchFromAPI<T>(thunkAPI, "get", path, null, queryParams);
  } else {
    return async <T>(
      thunkAPI: ThunkAPI,
      path: string,
      payload?: any,
      queryParams?: {[key: string]: string | number | boolean},
    ) => wrapped<T>(thunkAPI, method, path, payload, queryParams);
  }
}

/**
 * Wraps function and triggers global state saving and last save state changes
 */

async function wrapped<T>(
  thunkAPI: ThunkAPI,
  method: "post" | "put" | "patch" | "delete",
  path: string,
  payload: any,
  queryParams?: {[key: string]: string | number | boolean | undefined},
) {
  let requestId = "";
  const isNode = thunkAPI.extra.isNode;
  try {
    requestId = id();
    if (!isNode) {
      thunkAPI.dispatch(requestStarted({requestId, description: path}));
    }
    const result = await fetchFromAPI<T>(thunkAPI, method, path, payload, queryParams);
    if (!isNode) thunkAPI.dispatch(requestCompleted(requestId));
    return result;
  } catch (e: any) {
    if (!isNode && e?.code && e.name && e.message) {
      thunkAPI.dispatch(requestFailed({requestId, error: e}));
    }
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
}

export default {
  get: methodShortcut("get"),
  post: methodShortcut("post"),
  put: methodShortcut("put"),
  patch: methodShortcut("patch"),
  del: methodShortcut("delete"),
  delete: methodShortcut("delete"),
};

type MappedContractParams<Contract extends ApiEndpointContractBase> = {
  body: Contract["requestBody"];
  query: Contract["requestQuery"];
  path: Contract["pathPattern"];
  method: Contract["method"];
};
export function apiWithContract<Contract extends ApiEndpointContractBase>(
  thunkAPI: ThunkAPI,
  {method, query, body, path}: MappedContractParams<Contract>,
): Promise<Contract["responseBody"]> {
  if (method === "get") {
    return fetchFromAPI<Contract["responseBody"]>(thunkAPI, "get", path, null, query ?? {});
  } else {
    return wrapped<Contract["responseBody"]>(thunkAPI, method === "del" ? "delete" : method, path, body, query || {});
  }
}
