import {type EntityId} from "@reduxjs/toolkit";
import {extractFromCacheKey, mergeDiffs} from "@shared/data-functions/cache/cache-utilities";
import {
  getEmptyDatasourceDiff,
  getInsertAutoRangeDatasourceDiff,
  mergeDatasourceDiffObjects,
} from "@shared/lib/datasource-utilities";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {getIdsFromPayload, mapEntitiesToIds} from "@shared/lib/entity-functions";
import id, {getShortId} from "@shared/lib/id";
import {deleteCbTxLocal, triggerAlgoFromListener} from "@state/cb-tx/slice";
import {setLastSync, updateGlobalState} from "@state/global/slice";
import {isNode, isWebWorker} from "browser-or-node";
import dayjs from "dayjs";

import {createEffectHandler} from "../listeners-list";
import {api, applyDatasourceChanges, insertAutoRange, removeDatasourcesLocal} from "./slice";

import type {MonthlyCacheAndCbTxDiff} from "@shared/data-functions/cache/cache-utilities";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {Datasource} from "@shared/types/datasources";
import type {AppDispatch, RootState} from "@state/store";
import type {ApplyDatasourceChangesPayload} from "./slice";

export const syncDatasourceChangesWithAPI = createEffectHandler(applyDatasourceChanges, (action, {dispatch}) => {
  // If it's a global recalc, the diff is actually fake - the datasources are marked as updated but they are not
  // Skip saving them to the db
  if (action.payload.isGlobalRecalc) return;

  const {deletes = [], upserts = []} = action.payload.datasourceDiff;

  if (upserts.length) dispatch(api.upsert(upserts));
  if (deletes) {
    const ids = getIdsFromPayload(deletes);
    if (ids.length) dispatch(api.remove(ids));
  }
});

// A mapping of user ids to a queue of recalc requests
export const queues: Record<
  EntityId,
  {
    payload: ApplyDatasourceChangesPayload;
    state: RootState;
    originalState: RootState;
    dispatch: AppDispatch;
  }[]
> = {};
const isProcessingQueueMapping: Record<EntityId, boolean> = {};
const pendingPromisesMapping: Record<EntityId, Promise<void>> = {};

export const deleteTxForDeletedDatasources = createEffectHandler(
  [applyDatasourceChanges, removeDatasourcesLocal],
  (action, {getState, dispatch}) => {
    log("DeleteTxForDeletedDatasources", "Starting");
    const state = getState();
    let deletedDatasources: Datasource[] = [];
    if (applyDatasourceChanges.match(action)) {
      deletedDatasources = action.payload.datasourceDiff.deletes ?? [];
    } else {
      deletedDatasources = mapEntitiesToIds(state.datasources.entities, action.payload);
    }
    if (!deletedDatasources.length) return;
    const allTxIdsToDelete: string[] = [];
    for (const deletedDatasource of deletedDatasources) {
      const txIds = state.cbTx.idsByDsId[deletedDatasource.id] ?? [];
      allTxIdsToDelete.push(...txIds);
    }

    if (allTxIdsToDelete.length) {
      log("DeleteTxForDeletedDatasources", `Removing ${allTxIdsToDelete.length} transactions for deleted datasources`);
      dispatch(deleteCbTxLocal(allTxIdsToDelete));
    }
  },
);

export const triggerComputeAfterDatasourceChanges = createEffectHandler(
  applyDatasourceChanges,
  (action, {getState, dispatch, getOriginalState}) => {
    if (!isWebWorker && !isNode) return;
    const state = getState();
    const originalState = getOriginalState();
    const userId = state.session.user?.id;

    if (!userId) {
      // eslint-disable-next-line no-console
      console.error(`No user id found in session! This will break things!`);
      return;
    }

    dispatch(updateGlobalState({statusText: "Recalculating..."}));

    queues[userId] ||= [];
    const queue = queues[userId];
    log("ApplyDatasourceChanges listener", `Adding recalc to queue (reason: ${action.payload.reason})`);
    queue.push({
      payload: action.payload,
      state,
      originalState,
      dispatch,
    });

    if (isProcessingQueueMapping[userId]) return pendingPromisesMapping[userId];

    pendingPromisesMapping[userId] = new Promise((resolve, reject) => {
      isProcessingQueueMapping[userId] = true;
      (async () => {
        while (queue.length) {
          log("ApplyDatasourceChanges listener", "queue.length", queue.length);
          let deletedDatasourceIds: EntityId[] = [];
          let scenarioIdsImpacted: EntityId[] = [];
          let finalOptimisticDiff: MonthlyCacheAndCbTxDiff | undefined = undefined;
          let finalDatasourceDiff: DatasourceDiff = getEmptyDatasourceDiff();
          let sendOptimisticUpdate = false;
          let finalOriginalTemplateOptions = undefined;
          let finalDisableAddingPreviousDependencies = false;
          let finalIsGlobalRecalc: boolean | null = null;

          while (queue.length) {
            dispatch(updateGlobalState({statusText: "Recalculating..."}));
            const {
              payload: {
                datasourceDiff,
                optimisticDiff,
                reason,
                sendOptimisticUpdate: sendOptimisticUpdateForThisLoop,
                originalTemplateOptions: originalTemplateOptions,
                disableAddingPreviousDependencies,
                isGlobalRecalc,
              },
            } = queue.shift()!;
            finalIsGlobalRecalc = isGlobalRecalc === null ? !!isGlobalRecalc : finalIsGlobalRecalc && !!isGlobalRecalc;
            finalOriginalTemplateOptions = originalTemplateOptions;
            finalDisableAddingPreviousDependencies = !!disableAddingPreviousDependencies;
            log("ApplyDatasourceChanges listener", `Processing recalc request: ${reason}`);
            if (!sendOptimisticUpdate && sendOptimisticUpdateForThisLoop)
              sendOptimisticUpdate = sendOptimisticUpdateForThisLoop;
            finalOptimisticDiff = !finalOptimisticDiff
              ? optimisticDiff
              : optimisticDiff
                ? mergeDiffs(finalOptimisticDiff, optimisticDiff)
                : finalOptimisticDiff;

            finalDatasourceDiff = mergeDatasourceDiffObjects(finalDatasourceDiff, datasourceDiff);
          }
          const execId = getShortId();
          log(
            `ApplyDatasourceChanges listener (execId: ${execId})`,
            `Triggering algo for current loop iteration (number of pending items in the queue: ${queue.length})`,
          );
          time(`ApplyDatasourceChanges listener (execId: ${execId})`, `Completed algo run for current loop iteration`);

          scenarioIdsImpacted = [
            Object.values(finalDatasourceDiff.cacheKeysUpdatedPerDsId ?? {}),
            Object.values(finalDatasourceDiff.cacheKeysRemovedPerDsId ?? {}),
          ]
            .flat(2)
            .map((cacheKey) => extractFromCacheKey(cacheKey, "scenarioId"));

          dispatch(
            triggerAlgoFromListener({
              execId,
              datasourceDiff: finalDatasourceDiff,
              scenarioIdsImpacted: [...new Set(scenarioIdsImpacted)],
              deletedDatasourceIds: [...new Set(deletedDatasourceIds)],
              optimisticDiff: finalOptimisticDiff,
              sendOptimisticUpdate,
              originalTemplateOptions: finalOriginalTemplateOptions,
              disableAddingPreviousDependencies: finalDisableAddingPreviousDependencies,
              isGlobalRecalc: !!finalIsGlobalRecalc,
            }),
          );
          timeEnd(
            `ApplyDatasourceChanges listener (execId: ${execId})`,
            `Completed algo run for current loop iteration`,
          );
          log(
            `ApplyDatasourceChanges listener (execId: ${execId})`,
            `Done with loop iteration (number of pending items in the queue: ${queue.length})`,
          );
        }
        isProcessingQueueMapping[userId] = false;
        dispatch(updateGlobalState({statusText: null}));
        dispatch(
          setLastSync({
            messageId: id(),
            name: state.session.user?.name ?? "you",
            time: dayjs().format("MMMM D, YYYY [at] h:mm A"),
          }),
        );
        resolve();
      })();
    });
    return pendingPromisesMapping[userId];
  },
);

export const insertAutoRangeListener = createEffectHandler(insertAutoRange, async (action, {getState, dispatch}) => {
  const state = getState();

  const datasourceDiff = getInsertAutoRangeDatasourceDiff(state, action.payload);

  if (!datasourceDiff) return;

  let optimisticDiff: MonthlyCacheAndCbTxDiff = {
    updatedCacheKeys: {},
    deletedCacheKeys: [],
    upsertedCbTx: [],
    deletedCbTx: [],
  };

  dispatch(
    applyDatasourceChanges({
      reason: `Updated auto forecast for ${action.payload.row.name}`,
      datasourceDiff,
      optimisticDiff,
    }),
  );
});
