import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  formulaBuilderTabMain: {},

  forecastDestinationSection: {
    paddingBottom: 25,
  },

  clearAllFormulasButton: {
    marginTop: 25,
  },

  dropdownError: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    paddingTop: 15,
    color: colors.reds.red,

    extend: getSvgColorStyles(colors.reds.red),

    "& > *:not(:last-child)": {
      marginRight: 10,
    },
  },
});
