import type {RouteContext} from "@shared/types/server/Context";
import type {Knex} from "knex";

export type UtilityScriptHandler<RequiredContext = RouteContext> = (
  ctx: RequiredContext,
  options: {
    dryRun: boolean;
    allSchemas: boolean;
    log: (logText: string) => void;
    dbClient?: Knex;
  },
) => Promise<string>;

export type UtilityScriptResponsePayload = {
  duration: string;
  logLines: string[];
  summary: string | null;
  error: null | string;
};

export const utilityScriptDefinitions = [
  {
    name: "regenerate-team-slugs",
    displayName: "Regenerate team slugs",
    description:
      "Regenerates all team slugs based on team names. Useful if some team slugs were not generated properly or if the format of generating slugs ever changes to update them all at once.",
    dryRunEnabled: false,
  },
  {
    name: "regenerate-formula-references",
    displayName: "Regenerate formula references",
    description:
      "Regenerates formula references if they don't match the actual formula. Fixes situations where references are out of sync following, for instance, a row renaming bug.",
    dryRunEnabled: true,
  },
  {
    name: "extend-ranges-until-end",
    displayName: "Extend employee ranges",
    description:
      "Extends all the employee compensation until the end of forecast, if they are not terminated. Fixes situations where the end of the compensation was not dynamic and the forecast end has been pushed forward, leaving empty months at the end of the compensation for those employees.",
    dryRunEnabled: true,
  },
  {
    name: "recreate-all-hiring-plan-transactions",
    displayName: "Recreate all hiring plan transactions",
    description:
      "Recreates transactions for every employee compensation based on the value set in their compensation settings.",
    dryRunEnabled: true,
    disabled: true,
  },
  {
    name: "remove-missing-rows",
    displayName: "Remove ghost rows",
    description:
      "Scans all template orderings (the list that dictates the order and indentation of the rows in the template) and finds and deletes references to rows that don't exist.",
    dryRunEnabled: true,
  },
  {
    name: "add-rows-missing-from-ordering",
    displayName: "Add rows missing from ordering",
    description:
      "Scans all rows and all template orderings (the list that dictates the order and indentation of the rows in the template) and finds rows that are not in the ordering (so never displayed). It then adds them at the end so they can be deleted / moved properly.",
    dryRunEnabled: true,
  },
  {
    name: "invalidate-cache",
    displayName: "Invalidate cache",
    description:
      "Simply invalidate the Redis monthlyCache cache. This does not refresh the materialized view, but only tells the API to fetch it again from PostgreSQL next time instead of the cached version in Redis. Forces all connected users to this company to refresh.",
    dryRunEnabled: false,
  },
  {
    name: "refresh-materialized-view",
    displayName: "Refresh materialized view",
    description:
      "Refreshes the monthlyCache materialized view in PostgreSQL. Takes a few seconds on large datasets and forces users of this company to refresh.",
    dryRunEnabled: false,
  },
  {
    name: "recalc",
    displayName: "Run global recalc",
    description: `Recalculates the values of every single formula by calling the algorithm and marking all cells with a formula as "dirty".`,
    dryRunEnabled: false,
  },
  {
    name: "nuke-and-recalc",
    displayName: "Nuke transactions + recalc",
    description: `First deletes every single formula-generated transactions and then recreates them by calculating the values of every formula by calling the algorithm and marking all cells with a formula as "dirty".`,
    dryRunEnabled: false,
  },
  {
    name: "add-team-slugs-to-rows",
    displayName: "Add team slugs to hiring plan rows",
    description:
      "Adds the team slug to every compensation row if it's missing or out of sync. Could be useful in case of a team rename error where slug changes don't fully propagate to rows.",
    dryRunEnabled: false,
  },
  {
    name: "remove-empty-formula-ranges",
    displayName: "Remove empty formula ranges",
    description: `Finds all formula ranges that either have "" (empty string) or null as a formula. They should be completely removed instead of being kept but empty - it could cause some issues.`,
    dryRunEnabled: true,
    disabled: true,
  },
  {
    name: "remove-ghost-compensation-rows",
    displayName: "Remove ghost compensation rows",
    description: `Finds all compensation rows that are linked to an employee that doesn't exist and deletes them and any associated datasources and transactions.`,
    dryRunEnabled: true,
  },
  {
    name: "clear-all-qbo-transactions",
    displayName: "Clear all QBO transactions from the DB",
    description: `It finds all transactions that originate from QBO and deletes them in both the integration_tx cache and in the actual cb_tx aggregates table. Useful if for instance the template start has changed and we want to pull frensh with those dates without leaving unused data behind in the DB`,
    dryRunEnabled: true,
  },
  {
    name: "recreate-qbo-tx-aggregates",
    displayName: "Recreate QBO transaction aggregates",
    description: `It clears all the aggregates from the DB for QBO transactions and regenerates them from the raw integration transactions we have stored.`,
    dryRunEnabled: true,
  },
  {
    name: "fix-balance-sheet-ordering",
    displayName: "Fix balance sheet ordering",
    description: `Fixes the ordering of the balance sheet rows to match the standard ordering.`,
    dryRunEnabled: true,
  },
  {
    name: "remove-deleted-qbo-rows",
    displayName: "Remove deleted QBO rows",
    description: `Finds all rows in our system that are empty and linked to inactive (deleted) QBO accounts that also have no transactions`,
    dryRunEnabled: true,
  },
  {
    name: "regenerate-hiring-plan-row-slugs",
    displayName: "Regenerate hiring plan row slugs",
    description: `Regenerates all hiring plan row slugs based on the employee names. Useful to fix old row slugs like "unnamed_employee_1 or "future_hire_3"`,
    dryRunEnabled: true,
  },
  {
    name: "change-all-destination-rows-to-default",
    displayName: "Change all destination rows to default",
    description: `Changes the desitnation row for all PnL accounts to the default bank account selected in the settings.`,
    dryRunEnabled: true,
  },
] as const;

export type UtilityScriptNames = (typeof utilityScriptDefinitions)[number]["name"];
