import type {ExpressedAsOptions, HiringPlanShortcutType} from "./hiring-plan";

export interface BaseDatasource {
  id: string;
  row_id: string;
  scenario_id: string;
  start: string | null;
  end: string | null;
  department_id: string | null;
  dimensions?: {
    vendor?: string;
  };
  type: "integration" | "formula" | "hiring-plan-formula";
  integration_id?: string | null;
}

export interface HiringPlanFormulaDatasource extends BaseDatasource {
  type: "hiring-plan-formula";
  options: HiringPlanFormula;
}

export interface FormulaDatasource extends BaseDatasource {
  type: "formula";
  options: Formula;
}

export interface DbIntegrationDatasource extends BaseDatasource {
  type: "integration";
  integration_id: string;
  options: {
    remoteId: string | null;
  };
}

export type Datasource = BaseDatasource & (HiringPlanFormulaDatasource | FormulaDatasource | DbIntegrationDatasource);

export type FormulaReference = {
  from: string;
  to: string;
  row?: string | null;
  department?: string | null;
  vendor?: string | null;
  template?: string | null;
  total?: string | null;
  refStr: string;
  tags?: FormulaReferenceCbTags | Record<string, string>;
};

export type FormulaReferenceCbTags = {
  compensation_type?: string;
  compensation_name?: string;
  team?: string;
};

/**
 * @param type - has to be "integration"
 * @param id - ID of the integration
 * @param options - A list of options to attach to that datasource
 */

export type IntegrationDataProvider = {
  type: "integration";
  id: string;
  options: {
    remoteId: string | null;
  };
};
type BaseFormula = {
  formula: string | null;
  references?: FormulaReference[];
};

export type AutoUiOptions = {
  type: "auto";
  timePeriod: string;
} & (
  | {
      formulaType: "pct-of-target";
      target: string;
      targetDepartment?: string | null;
      targetVendor?: string | null;
    }
  | {
      formulaType: "average" | "median";
    }
);

export const preBuiltFormulaTypes = [
  "gross-profit",
  "net-operating-income",
  "net-income",
  "net-other-income",
  "net-income-bs",
  "net-cash-increase",
] as const;

export type PreBuiltFormulaTypes = (typeof preBuiltFormulaTypes)[number];

export type PreBuiltUiOptions = {
  type: "pre-built";
  formulaType: PreBuiltFormulaTypes | null;
};

export type CustomUiOptions = {
  type: "custom";
  timePeriod: string;
  formulaType: null;
};

export type Formula = BaseFormula & {
  ui?: AutoUiOptions | PreBuiltUiOptions | CustomUiOptions;
};

export type HiringPlanFormulaUIOptions = {
  expressedAs?: ExpressedAsOptions;
  type: HiringPlanShortcutType;
  value: string;
  target?: string;
};

export type HiringPlanFormula = BaseFormula & {
  ui: HiringPlanFormulaUIOptions;
  fc_name: string;
  employee_id: string;
};

export type FormulaDataProvider = {
  type: "formula";
  options: Formula;
};

export type HiringPlanFormulaDataProvider = {
  type: "hiring-plan-formula";
  options: HiringPlanFormula;
};
