import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";

import adminApi from "./api";

import type {User, UserInvite} from "@shared/types/db";

export const api = {
  runUtility: createAsyncThunk("admin/utilities/runUtility", adminApi.runUtility),
  fetchAllUsers: createAsyncThunk("admin/users/fetchAll", adminApi.fetchAllUsers),
  fetchAllInvites: createAsyncThunk("admin/invites/fetchAll", adminApi.fetchAllInvites),
  deleteUser: createAsyncThunk("admin/users/delete", adminApi.deleteUser),
  createUser: createAsyncThunk("admin/users/create", adminApi.createUser),
  updateUser: createAsyncThunk("admin/users/update", adminApi.updateUser),
};

export interface AdminState {
  users: null | User[];
  invites: null | UserInvite[];
}

const initialState: AdminState = {
  users: null,
  invites: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(api.fetchAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(api.fetchAllInvites.fulfilled, (state, action) => {
      state.invites = action.payload;
    });
    builder.addCase(api.updateUser.fulfilled, (state, action) => {
      console.log({state: current(state)});
      if (state.users) {
        const userIndex = state.users.findIndex((user) => user.id === action.payload.id);
        state.users[userIndex] = action.payload;
      }
    });
  },
});

export const selectAllUsers = (state: {admin: AdminState}) => state.admin.users;
export const selectAllUsersByCompanyId = (state: {admin: AdminState}) =>
  Object.groupBy(state.admin.users ?? [], (user: User) => user.company_id);

// Invites
export const selectAllInvites = (state: {admin: AdminState}) => state.admin.invites;
export const selectAllInvitesByUserId = (state: {admin: AdminState}) =>
  Object.groupBy(state.admin.invites ?? [], (invite: UserInvite) => invite.user_id);

export default adminSlice.reducer;
