import {useAppDispatch, useAppSelector} from "@app/hooks";
import FormElement from "@components/FormElement";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import DatasourceSelector from "@features/shared-views/DatasourceSelector";
import {selectRowByName} from "@shared/state/template-rows/selectors";
import {upsertRow} from "@shared/state/template-rows/slice";

import useStyles from "./styles.jss";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import SVGIcon from "@components/SVGIcon";
import type {AccountTemplateRow, TemplateRow} from "@shared/types/db";

export interface ForecastDestinationProps {
  row: TemplateRow;
  disabled: boolean;
}

export default function ForecastDestination({row, disabled}: ForecastDestinationProps) {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const destinationRow = useAppSelector((state) =>
    selectRowByName(state, (row as AccountTemplateRow).options.destinationRow || ""),
  );

  const handleDestinationRowChange = (newDestinationRow: string | null) => {
    if (row.type !== "account") return;
    logUserEvent("forecast-destination-change", {
      rowId: row.name,
      oldDestinationRow: row.options.destinationRow,
      newDestinationRow,
    });

    dispatch(
      upsertRow({
        ...row,
        options: {...row.options, destinationRow: newDestinationRow},
      } as AccountTemplateRow),
    );
  };

  return (
    <>
      {row.type === "account" && !row.mirror_of && row.options.type !== "Bank" ? (
        <Section
          title="Forecast Destination (DR / CR)"
          collapsed
          wrapContent={false}
          summary={destinationRow?.display_name}
        >
          <SectionCol>
            <FormElement label="Forecast Destination" className={styles.forecastDestinationSection}>
              <DatasourceSelector
                onDatasourceSelect={(row) => handleDestinationRowChange(row?.name ?? null)}
                selectedKey={destinationRow?.id ?? row.options.destinationRow}
                allowEmpty
                filter={(row) => !!(row.type === "account" && row.options.balance)}
                width={500}
                align="end"
                disabled={disabled}
              />
              {!destinationRow && !!row.options.destinationRow && (
                <div className={styles.dropdownError}>
                  <SVGIcon name="errorExclamationPoint" />
                  <span>Unknown row "{row.options.destinationRow}".</span>
                </div>
              )}
            </FormElement>
          </SectionCol>
        </Section>
      ) : null}
    </>
  );
}
