import {getForecastTypeForRow} from "@shared/lib/row-utilities";

import type {IconNames} from "@app/IconNames";
import {DropdownItem} from "@components/Dropdown";
import type {EntityId} from "@reduxjs/toolkit";
import {preBuiltFormulaTypes, type FormulaDatasource, type PreBuiltFormulaTypes} from "@shared/types/datasources";
import type {TemplateRow} from "@shared/types/db";
import memoizeOne from "memoize-one";

export const getForecastTypes = memoizeOne((rowType: TemplateRow["type"]) => {
  const items: DropdownItem[] = [
    {
      key: "custom",
      value: "Custom",
      iconLeft: "cog" as IconNames,
    },
    {
      key: "auto",
      value: "Automatic",
      iconLeft: "sync" as IconNames,
    },
  ];
  if (rowType !== "account") {
    items.push({
      key: "pre-built",
      value: "Pre-built Formula",
      iconLeft: "formula" as IconNames,
    });
  }

  return items;
});

export const formulaTypes = [
  {
    key: "average",
    value: "Average",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "median",
    value: "Median",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "pct-of-target",
    value: "% of Row",
    iconLeft: "formula" as IconNames,
  },
] as const;

export const preBuiltFormulas = [
  {
    key: "gross-profit",
    value: "Gross Profit",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "net-operating-income",
    value: "Net Operating Income",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "net-other-income",
    value: "Net Other Income",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "net-income",
    value: "Net Income",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "net-income-bs",
    value: "Net Income (Balance Sheet)",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "net-cash-increase",
    value: "Net Cash Increase",
    iconLeft: "formula" as IconNames,
  },
] as const satisfies (DropdownItem & {key: PreBuiltFormulaTypes})[];

export const formulaTypeToLabelMapping = Object.fromEntries(formulaTypes.map((item) => [item.key, item.value]));

export function getFormulaSidebarRowInfos({
  row,
  autoForecastDatasourceFormula,
  departmentId,
  vendor,
}: {
  row: TemplateRow;
  autoForecastDatasourceFormula: FormulaDatasource | undefined;
  departmentId?: EntityId | null;
  vendor?: string | null;
}) {
  const currentForecastType = getForecastTypeForRow(row, departmentId ?? null);
  const forecastTypeSummary =
    row.type === "account" && row.options.forecastType === "department" ? "By Department" : "By Row";
  const formulaTypeSummary = !autoForecastDatasourceFormula
    ? "Custom"
    : formulaTypeToLabelMapping[autoForecastDatasourceFormula.options.ui?.formulaType ?? "average"];
  const rowCanBeForecasted =
    (currentForecastType === "department" && departmentId && !vendor) ||
    (currentForecastType === "vendor" && vendor) ||
    (currentForecastType === "row" && !departmentId && !vendor);
  const forecastGranularityHidden =
    row.type !== "account" ||
    !!vendor ||
    ((row.options.forecastType === "row" || !row.options.forecastType) && !!departmentId);
  return {
    forecastTypeSummary,
    formulaTypeSummary,
    rowCanBeForecasted,
    forecastGranularityHidden,
  };
}

export function isPreBuiltFormulaType(key: string): key is PreBuiltFormulaTypes {
  return preBuiltFormulaTypes.includes(key as PreBuiltFormulaTypes);
}
