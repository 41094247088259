import {store} from "@app/client-store";
import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {useEffect} from "react";
import {useKey} from "react-use";

import {selectIsCellDisabled, selectors} from "./state/selectors";
import {
  addMultipleCellsToSelection,
  applyFormulaToCells,
  clearFormulaForSelectedCells,
  enableEditing,
  expandSelectionByOne,
  moveSelectedCell,
  setPastedValuesToCells,
} from "./state/thunks";
import {getSelectedCellsData} from "./state/utils";

import {handlePastedData} from "@/lib/row-utilities-client";
import {logUserEvent} from "@app/websockets/websocket-action-logger";

const cmdKey = navigator.platform.includes("Win") ? "ctrlKey" : "metaKey";

export function useTemplateShortcuts() {
  const dispatch = useAppThunkDispatch();
  const activeCell = useAppSelector(selectors.activeCell);
  const cellBeingEdited = useAppSelector(selectors.cellBeingEdited);
  const highlightedDateKeys = useAppSelector(selectors.highlightedDateKeys);

  // Copy and paste handler
  useEffect(() => {
    // Global paste event listener
    const onPaste = (event: ClipboardEvent) => {
      const state = store.getState();
      const disabled = selectIsCellDisabled(state);

      if (!disabled && activeCell && !cellBeingEdited) {
        // First check if the active cell is editable

        const {values, clipboardData} = handlePastedData(event);
        logUserEvent("paste-in-cell", {insideTextInput: false, cellBeingEdited, clipboardData, parsedData: values});
        // Copy your handlePastedData function here or import it

        // Update the grid data starting from the selected cell
        dispatch(setPastedValuesToCells(values));
      }
    };

    window.addEventListener("paste", onPaste);

    return () => {
      window.removeEventListener("paste", onPaste);
    };
  }, [activeCell, dispatch]);

  // Copy handler
  useEffect(() => {
    const onCopy = (event: ClipboardEvent) => {
      // Check if any text is selected
      const selectedText = window.getSelection()?.toString();

      console.log(selectedText, activeCell, getSelectedCellsData(store.getState()));

      // If text is selected, don't override the clipboard data
      if (selectedText && selectedText.length > 0) {
        return;
      }

      if (activeCell) {
        // Assuming getSelectedCellsData() will return the data of the selected cells in a 2D array.
        // You'll need to implement this function based on how you're managing grid state.
        const state = store.getState();
        const selectedData = getSelectedCellsData(state);

        // Prevent the default copy operation
        event.preventDefault();

        // Set the modified clipboard data
        event.clipboardData?.setData("Text", selectedData ?? "");

        // Log the user event
        logUserEvent("copy-from-cell", {insideTextInput: false, activeCell, copiedData: selectedData});
      }
    };

    window.addEventListener("copy", onCopy);

    return () => {
      window.removeEventListener("copy", onCopy);
    };
  }, [activeCell]);

  // F2 and Enter key handler - enables editing but doesn't replace anything
  useKey(
    (evt) => !!activeCell.column && !!activeCell.rowId && !cellBeingEdited && ["F2", "Enter"].includes(evt.key),
    () => {
      logUserEvent("shortcut-used", {context: "cell", shortcut: "F2"});
      dispatch(enableEditing());
    },
    undefined,
    [activeCell.column, activeCell.rowId],
  );

  // Delete, Clear and Backspace key handler - clears the formula of the selected cells
  useKey(
    (evt) => {
      return !!activeCell.column && !!activeCell.rowId && ["Delete", "Clear", "Backspace"].includes(evt.key);
    },
    (evt) => {
      logUserEvent("shortcut-used", {context: "cell", shortcut: evt.key});
      dispatch(clearFormulaForSelectedCells());
    },
    undefined,
    [activeCell.column, activeCell.rowId],
  );

  // Enable editing on typing, replacing the cell value with the typed character
  useKey(
    (evt) => !!activeCell.column && evt.key.length === 1 && !evt.ctrlKey && !evt.metaKey && !evt.altKey,
    (evt) => {
      logUserEvent("shortcut-used", {context: "cell", shortcut: "[started typing on cell]"});
      dispatch(enableEditing("cell", evt.key));
      evt.stopPropagation();
      evt.preventDefault();
    },
    {event: "keydown"},
  );

  const handlerKeys = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown", "Tab", "r"];
  useKey(
    (evt) => handlerKeys.includes(evt.key),
    (evt) => {
      if (!activeCell.column) return;
      let cancelPropagation = true;
      switch (evt.key) {
        case "ArrowRight": {
          if (evt[cmdKey] && evt.shiftKey) {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "cmd + shift + ArrowRight"});
            dispatch(addMultipleCellsToSelection("right"));
          } else if (!evt.shiftKey) {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "ArrowRight"});
            dispatch(moveSelectedCell("right"));
          } else {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "shift + ArrowRight"});
            dispatch(expandSelectionByOne("right"));
          }
          break;
        }
        case "ArrowLeft": {
          if (evt[cmdKey] && evt.shiftKey) {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "cmd + shift + ArrowLeft"});
            dispatch(addMultipleCellsToSelection("left"));
          } else if (!evt.shiftKey) {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "ArrowLeft"});
            dispatch(moveSelectedCell("left"));
          } else {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "shift + ArrowLeft"});
            dispatch(expandSelectionByOne("left"));
          }
          break;
        }
        case "Tab": {
          logUserEvent("shortcut-used", {context: "cell", shortcut: "Tab"});
          dispatch(moveSelectedCell(evt.shiftKey ? "left" : "right"));
          break;
        }
        case "ArrowUp": {
          logUserEvent("shortcut-used", {context: "cell", shortcut: "ArrowUp"});
          dispatch(moveSelectedCell("up"));
          break;
        }
        case "ArrowDown": {
          logUserEvent("shortcut-used", {context: "cell", shortcut: "ArrowDown"});
          dispatch(moveSelectedCell("down"));
          break;
        }
        case "r": {
          if (evt[cmdKey] && highlightedDateKeys?.length) {
            logUserEvent("shortcut-used", {context: "cell", shortcut: "cmd + r"});
            dispatch(applyFormulaToCells("highlighted"));
          } else {
            cancelPropagation = false;
          }
          break;
        }
      }

      if (cancelPropagation) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    {event: "keydown"},
  );
}
