import colors from "@app/styles/colors";
import {containerShadowStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  adminMain: {
    width: "100%",
    height: "100%",
    margin: [0, "auto"],
    padding: [25, 0],
    fontWeight: 400,
    position: "absolute",
    zIndex: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      marginBottom: 40,
    },
  },

  tabs: {
    extend: containerShadowStyles,
    padding: 25,
    borderRadius: 5,
    width: 1000,
    background: "white",
    height: "100%",
  },

  content: {
    paddingTop: 25,
    overflowX: "auto",
    height: "calc(100% - 10px)",
  },

  utilities: {
    paddingRight: 25,
    "& > div": {
      marginBottom: 15,
    },
  },

  company: {
    "&:nth-child(odd)": {
      background: colors.ui.lightBlue07,
    },
  },

  companyUsers: {
    padding: 8,
    fontSize: 14,

    "& th, & td": {
      textAlign: "left",
      padding: [4, 0],
      verticalAlign: "top",

      "&:not(:first-child)": {
        paddingLeft: 15,
      },
    },

    "& td": {
      verticalAlign: "middle",
    },

    "& th": {
      fontWeight: 600,
      paddingBottom: 16,
    },

    "& tr": {},

    "& table": {
      marginTop: 10,
      width: "100%",
    },
  },

  user: {
    display: "flex",
    alignItems: "center",
    height: " 100%",
  },

  userName: {
    marginRight: 15,
    marginLeft: 8,
  },
  email: {fontStyle: "italic"},

  userButtons: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",

    "& > *": {
      marginLeft: 15,
    },
  },

  companyFirstRow: {
    display: "flex",
    alignItems: "center",
    padding: [8],
    paddingRight: 0,

    "& > div:not($name)": {
      margin: [0, 8],
    },
  },

  inlineEditForm: {
    display: "flex",
    alignItems: "center",

    "& > div": {
      marginRight: 15,
      minWidth: 300,
    },
  },

  scriptDescription: {
    color: colors.text.black,
    fontSize: 14,
    paddingTop: 15,

    "& > h4": {
      fontSize: 14,
      fontWeight: 600,
      paddingBottom: 15,
    },

    "& > p": {
      lineHeight: 17,
      paddingBottom: 15,
    },
  },
  runButton: {
    marginTop: 10,
  },
  name: {
    flex: 1,
  },
  utilitySelectRow: {
    display: "flex",
    "& > :first-child": {
      marginRight: 30,
    },
  },
  subtext: {},
  success: {},
  error: {},
  resultLabel: {
    fontWeight: 600,
    marginRight: 8,
  },
  resultIcon: {
    marginRight: 8,

    "&$error": {
      ...getSvgColorStyles(colors.reds.red),
    },
    "&$success": {
      ...getSvgColorStyles(colors.secondary.green),
    },
  },
  utilityExecResult: {
    paddingTop: 15,
    fontSize: 14,

    "& > h4": {
      fontSize: 14,
      fontWeight: 600,
      paddingBottom: 15,

      "& $subtext": {
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 10,
        color: colors.text.lightBlue01,
      },
    },

    "& pre": {
      fontFamily: "'Red Hat Mono', 'Courier New'",
      fontSize: 13,
      lineHeight: 15,

      "&:last-child": {
        paddingBottom: 15,
      },
    },
  },

  logLines: {
    background: colors.ui.black,
    color: colors.text.white,
    borderRadius: 5,
    paddingLeft: 15,
    paddingTop: 15,
    overflowX: "auto",
  },

  summary: {padding: [10, 0]},

  scenarioChecks: {
    fontSize: 13,
    marginBottom: 20,

    "& h2": {
      marginBottom: 0,
    },

    "& th, & td": {
      whiteSpace: "nowrap",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      textAlign: "right",
      padding: [5, 5],
      border: ["solid", 1, "black"],

      "&:first-child": {
        width: 125,
        minWidth: 125,
        maxWidth: 125,
      },

      "&$balances": {
        background: colors.secondary.lightGreen05,
      },

      "&$doesNotBalance": {
        background: colors.reds.lightRed02,
      },
    },

    "& th": {
      fontWeight: 600,
    },
  },
  checkTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,

    "& > span": {
      marginLeft: 8,
    },

    "&$balances": {
      ...getSvgColorStyles(colors.secondary.green),
    },

    "&$doesNotBalance": {
      color: colors.reds.red,
      ...getSvgColorStyles(colors.reds.red),
    },
  },
  balances: {},
  doesNotBalance: {},

  stats: {
    paddingRight: 20,
  },

  cacheStatsTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: 20,

    "& th, & td": {
      border: `1px solid ${colors.ui.black}`,
      padding: 8,
      textAlign: "left",
    },

    "& th": {
      backgroundColor: colors.ui.lightBlue04,
      fontWeight: "bold",
    },

    "& tr:nth-child(even)": {
      backgroundColor: colors.ui.lightBlue07,
    },
  },
  templateSettings: {},
  table: {
    borderCollapse: "collapse",
    width: "100%",
    borderColor: colors.ui.black,

    "&$disabled": {
      opacity: 0.4,
      userSelect: "none",
      pointerEvents: "none",
    },
  },
  th: {
    border: `1px solid ${colors.ui.black}`,
    padding: 8,
  },
  td: {
    verticalAlign: "middle",
    border: `1px solid ${colors.ui.lightBlue01}`,
    padding: 8,

    "&:first-child": {
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  },
  disabled: {},
  templateChangeWarning: {
    paddingTop: 25,
    lineHeight: 22,
    color: colors.text.darkBlue01,

    "& > h2": {
      color: colors.reds.red,
      marginBottom: 25,
    },

    "& > ul": {
      paddingLeft: 25,
      marginTop: 25,
      display: "flex",
      flexDirection: "column",

      "& > li": {
        display: "inline-flex",
        alignItems: "center",

        "& > span": {
          marginRight: 10,
        },

        "& $resultIcon": {
          marginLeft: 10,
        },
      },
    },
  },
});
