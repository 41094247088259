import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import Button from "@components/Button/V2";
import Checkbox from "@components/Checkbox";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import Modal from "@components/Modal";
import Tabs from "@components/Tabs";
import TextInput from "@components/TextInput";
import {selectors} from "@features/templates/state/selectors";
import id from "@shared/lib/id";
import {
  createScenario,
  removeScenario,
  selectAllScenarios,
  upsertScenario,
  upsertScenarios,
} from "@state/scenarios/slice";
import {useEffect, useState} from "react";

import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
import {Scenario} from "@shared/types/db";

interface CreateNewScenarioProps {
  onClose: () => void;
  editedScenario?: Scenario | null;
}

const tabsList = [{name: "details", value: "Details"}];

export default function CreateNewScenario({onClose, editedScenario = null}: CreateNewScenarioProps) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const [scenarioDraft, setScenarioDraft] = useState<Scenario>({
    id: editedScenario?.id ?? id(),
    name: editedScenario?.name ?? "",
    description: editedScenario?.description ?? "",
    default: editedScenario?.default ?? false,
  });

  const [name, setName] = useState(scenarioDraft.name);
  const [description, setDescription] = useState(scenarioDraft.description);
  const [isDefault, setIsDefault] = useState(scenarioDraft.default);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const scenarios = useAppSelector(selectAllScenarios);
  const progressText = useAppSelector(selectors.createScenarioProgress);

  const [duplicatedScenario, setDuplicatedScenario] = useState(
    (scenarios.find((scenario) => scenario.default) ?? scenarios[0]).id,
  );

  useEffect(() => {
    if (scenarios.length) {
      const defaultScenario = scenarios.find((scenario) => scenario.default);
      setDuplicatedScenario(defaultScenario?.id ?? scenarios[0].id);
    }
  }, [setDuplicatedScenario, scenarios]);

  const items = [
    // {key: "none", value: "None"},
    ...scenarios.map((scenario) => ({key: scenario.id, value: scenario.name})),
  ];

  const handleSave = async () => {
    if (editedScenario) {
      // Edit existing scenario
      dispatch(upsertScenarios([{...scenarioDraft, name, description, default: isDefault}]));
      dispatch(upsertScenario({...scenarioDraft, name, description, default: isDefault}));
      onClose();
    } else {
      // Create new scenario
      setSaving(true);
      dispatch(
        createScenario({
          basedOn: duplicatedScenario === "none" ? null : duplicatedScenario,
          scenario: {id: id(), name, description, default: isDefault},
        }),
      );
    }
  };

  const handleDelete = () => {
    dispatch(
      askForConfirmation({
        preset: "delete",
        onConfirm: () => {
          dispatch(removeScenario(scenarioDraft.id));
          setDeleting(true);
        },
        text: `Are you sure you want to delete the scenario <b>${scenarioDraft.name}</b>? This action cannot be undone.`,
      }),
    );
  };

  const handleDuplicateScenarioSelect = (item: DropdownItem) => {
    setDuplicatedScenario(item?.key ?? "none");
  };

  return (
    <Modal onClose={onClose} backgroundImage="/assets/images/ui/scenario-modal-bg.svg">
      <div className={styles.createScenarioMain}>
        <h2 className={styles.modalTitle}>{editedScenario ? "Edit Scenario" : "Create New Scenario"}</h2>
        <Tabs tabs={tabsList} selectedTab={tabsList[0]} onChange={() => null} />

        <div className={styles.firstRow}>
          <div>
            <FormElement label="Scenario Name">
              <TextInput
                placeholder="Enter Scenario name..."
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                disabled={saving || deleting}
              />
            </FormElement>
          </div>
          {!editedScenario && (
            <div>
              <FormElement label={"Copy Existing Scenario"}>
                <Dropdown
                  items={items}
                  onSelect={handleDuplicateScenarioSelect}
                  renderAsChild
                  selectedKey={duplicatedScenario}
                  disabled={saving || deleting}
                />
              </FormElement>
            </div>
          )}
        </div>
        <div className={styles.secondRow}>
          <FormElement label="Description" className={styles.secondRowFormElement}>
            <TextInput
              multiline
              placeholder="Scenario description..."
              className={styles.inputWrapper}
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              disabled={saving || deleting}
            />
          </FormElement>
        </div>
        <div className={styles.thirdRow}>
          <Checkbox
            text="Set as the Default Scenario"
            onClick={() => setIsDefault(!isDefault)}
            checked={isDefault}
            disabled={saving || deleting}
            color="orange"
          />
        </div>
        <div className={styles.buttonsRow}>
          {editedScenario && <Button color="red" text="Delete" onClick={handleDelete} disabled={saving || deleting} />}
          <div style={{flex: 1}} /> {/* Spacer */}
          <Button text="Cancel" color="grey" onClick={onClose} disabled={saving || deleting} isLink />
          <Button
            color="orange"
            text={editedScenario ? "Save" : "Create"}
            onClick={handleSave}
            disabled={saving || !name || deleting}
          />
          {!editedScenario && saving && duplicatedScenario !== "none" ? (
            <div className={styles.statusText}>
              <span>{progressText || "Creating scenario copy... This could take a minute or two."}</span>
            </div>
          ) : null}
          {deleting && (
            <div className={styles.statusText}>
              <span>Deleting scenario...</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
