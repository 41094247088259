import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import Button from "@components/Button/V2";
import FormElement from "@components/FormElement";
import Modal from "@components/Modal";
import TextInput from "@components/TextInput";
import {useEffect, useState} from "react";

import useStyles from "../CreateScenario/styles.jss";

import Dropdown, {DropdownItem} from "@components/Dropdown/DropdownV2";
import {getUniqueSlug} from "@shared/lib/slug";
import {AccountTemplateRow, CustomVendor, Department, TemplateRow} from "@shared/types/db";
import {selectAllDepartments, selectDepartmentEntities} from "@state/departments/slice";
import {selectAllVendors} from "@state/integrations/slice";
import {selectTemplateRowEntities, upsertRows} from "@state/template-rows/slice";
import DatasourceSelector from "../DatasourceSelector";
import DepartmentSelector from "../DepartmentSelector";

interface CreateVendorProps {
  onClose: () => void;
  rowId?: string;
  departmentId?: string;
  editedCustomVendor?: CustomVendor;
}

export default function CreateVendor({onClose, rowId, departmentId, editedCustomVendor}: CreateVendorProps) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const departments = useAppSelector(selectAllDepartments);
  const departmentsById = useAppSelector(selectDepartmentEntities);
  const rowsById = useAppSelector(selectTemplateRowEntities);

  const allVendors = useAppSelector(selectAllVendors);

  const [vendorIds, setVendorsIds] = useState(allVendors.map((vendor) => vendor.id));

  // Keep vendorsIds up to date if allVendors changes
  useEffect(() => {
    setVendorsIds(allVendors.map((vendor) => vendor.id));
  }, [allVendors]);

  const editedVendorRow =
    rowId && rowsById[rowId] && rowsById[rowId].type === "account" ? (rowsById[rowId] as AccountTemplateRow) : null;
  const editedVendorDepartment =
    departmentId && departmentsById[departmentId]
      ? departmentsById[departmentId]
      : departments.length === 1
      ? departments[0]
      : null;

  const [customVendor, setCustomVendor] = useState<CustomVendor>(
    editedCustomVendor ?? {
      name: "",
      display_name: "",
      type: "vendor",
    },
  );

  const [account, setAccount] = useState<AccountTemplateRow | null>(editedVendorRow);
  const [department, setDepartment] = useState<Department | null>(editedVendorDepartment);

  const handleSave = async () => {
    if (!department || !account) return;

    const newCustomVendorsOnRow = {
      ...(account.options.customVendors ?? {}),
    };

    newCustomVendorsOnRow[department.id] = [...(newCustomVendorsOnRow[department.id] ?? [])];
    newCustomVendorsOnRow[department.id].push(customVendor);

    dispatch(upsertRows([{...account, options: {...account.options, customVendors: newCustomVendorsOnRow}}]));

    onClose();
  };

  const handleAccountSelect = async (row: TemplateRow | null) => {
    if (row?.type === "account") {
      setAccount(row);
    }
  };

  const handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCustomVendor({
      ...customVendor,
      display_name: evt.target.value,
      name: getName(customVendor.type, evt.target.value),
    });
  };

  const getName = (type: string, display_name: string) => {
    const prefix = type === "employee" ? "emp_" : type === "customer" ? "cus_" : "";
    return getUniqueSlug(`${prefix}-${display_name}`, vendorIds).slug;
  };

  const handleTypeChange = (item: DropdownItem) => {
    const name = getName(item.key ?? "vendor", customVendor.display_name);
    setCustomVendor({...customVendor, type: item.key ?? "vendor", name});
  };

  const handleDepartmentChange = (item: DropdownItem) => {
    const selectedDepartment = departments.find((department) => department.id === item.key);
    setDepartment(selectedDepartment ?? null);
  };

  const handleKeyDown = (evt: React.KeyboardEvent) => {
    evt.stopPropagation();

    // If it's esc, close the modal
    if (evt.key === "Escape") {
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} backgroundImage="/assets/images/ui/vendors-modal-bg.svg">
      <div className={styles.createScenarioMain} onKeyDown={handleKeyDown}>
        <h2 className={styles.modalTitle}>Create Vendor</h2>

        <div className={styles.firstRow}>
          <div>
            <FormElement label="Vendor Name">
              <TextInput
                placeholder="Enter Vendor name..."
                value={customVendor.display_name}
                onChange={handleNameChange}
                tabIndex={0}
              />
            </FormElement>
          </div>
          <div>
            <FormElement label="Type" className={styles.secondRowFormElement}>
              <Dropdown
                items={[
                  {key: "vendor", value: "Vendor"},
                  {key: "customer", value: "Customer"},
                  {key: "employee", value: "Employee"},
                ]}
                selectedKey={customVendor.type}
                onSelect={handleTypeChange}
                tabIndex={1}
              />
            </FormElement>
          </div>
        </div>
        <div className={styles.secondRowVendor}>
          <FormElement label="Account" className={styles.secondRowFormElement}>
            <DatasourceSelector
              position={["bottom", "top"]}
              placeholder="Select an Account..."
              selectedRowName={account?.name}
              onDatasourceSelect={handleAccountSelect}
              filter={(row) => row.type === "account"}
              tabIndex={2}
            />
          </FormElement>
          {!!department && departments.length === 1 ? null : (
            <FormElement label="Department" className={styles.secondRowFormElement}>
              <DepartmentSelector
                selectedKey={department?.id}
                onChange={handleDepartmentChange}
                nullLabel="Select a Department..."
              />
            </FormElement>
          )}
        </div>

        <div className={styles.buttonsRow}>
          <div style={{flex: 1}} /> {/* Spacer */}
          <Button text="Cancel" color="grey" onClick={onClose} isLink />
          <Button
            color="orange"
            text={"Create"}
            onClick={handleSave}
            disabled={!customVendor.display_name.length || !department || !account}
          />
        </div>
      </div>
    </Modal>
  );
}
