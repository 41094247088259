import RoundButton from "@components/RoundButton";
import clsx from "clsx";
import debounce from "lodash.debounce";
import * as React from "react";
import {useEffect, useLayoutEffect, useMemo, useState} from "react";
import TextareaAutosize from "react-textarea-autosize";

import useStyles from "./styles.jss";

import type {IconNames} from "@components/SVGIcon";

import {useForwardRef} from "@/lib/misc";

type Formatter = (value: string) => string;

const noop = () => null;

export interface TextInputProps {
  autocomplete?: string;
  disabled?: boolean;
  className?: string;
  focusOnRender?: boolean;
  formatter?: Formatter;
  minimal?: boolean;
  name?: string;
  onBlur?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onEnterKeypress?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  changeTrigger?: "keyup" | "blur";
  onChangeDelay?: number;
  placeholder?: string;
  plusMinusButtons?: boolean;
  rightButtonIcon?: IconNames;
  onRightButtonClick?: React.MouseEventHandler<HTMLElement>;
  type?: "text" | "password";
  value?: string | null;
  shadowText?: boolean;
  multiline?: boolean;
  autosize?: boolean;
  maxrows?: number;
  tabIndex?: number;
}

export default React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(function TextInput(
  {
    autocomplete,
    disabled = false,
    className,
    focusOnRender = false,
    formatter,
    name,
    onBlur,
    onChange,
    onChangeDelay = 0,
    onKeyDown,
    changeTrigger = "keyup",
    placeholder,
    plusMinusButtons,
    rightButtonIcon,
    type = "text",
    value,
    onRightButtonClick,
    onEnterKeypress,
    shadowText = false,
    multiline = false,
    autosize = false,
    maxrows = 10,
    tabIndex,
  },
  forwardedRef,
) {
  const inputRef = useForwardRef<HTMLInputElement | HTMLTextAreaElement>(forwardedRef);
  const [inputPadding, setInputPadding] = useState("0");
  const [isFocused, setIsFocused] = useState(false);
  const [localText, setLocalText] = useState<string | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (focusOnRender) inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    setLocalText(null);
  }, [value]);

  useLayoutEffect(() => {
    if (!inputRef.current) return;
    const nodeStyle = window.getComputedStyle(inputRef.current);
    setInputPadding(nodeStyle.getPropertyValue("padding"));
  }, [className]);

  const classes = clsx(className, styles.textInputMain, {
    [styles.disabled]: disabled,
    [styles.hasPlusMinusButtons]: plusMinusButtons,
  });

  let displayedValue = localText !== null ? localText : value || "";
  if (!isFocused && formatter) displayedValue = formatter(displayedValue);

  const debouncedOnChange = useMemo(() => debounce(onChange || noop, onChangeDelay), [onChange, onChangeDelay]);

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isFocused) setIsFocused(false);
    setLocalText(null);

    if (onBlur) onBlur(evt);

    if (value !== evt.target.value && onChange && changeTrigger === "blur") onChange(evt);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalText(evt.target.value);
    if (disabled || value === evt.target.value) return;
    if (onChange) {
      if (changeTrigger === "keyup") {
        onChange(evt);
      } else if (!!onChangeDelay) {
        debouncedOnChange(evt);
      }
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (evt) => {
    if (disabled || plusMinusButtons) return;
    setLocalText(value || "");
    setIsFocused(true);
    if (value === "0") setTimeout(() => evt.target.select(), 5);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (evt) => {
    // If it's enter, call blur()
    if (!multiline && evt.key === "Enter") {
      inputRef.current?.blur();
      if (onEnterKeypress) onEnterKeypress(evt);
      return;
    }

    if (!multiline && !["ArrowUp", "ArrowDown", "Tab", "Enter", "Escape"].includes(evt.key)) evt.stopPropagation();
    if (
      !multiline &&
      ["ArrowDown", "ArrowUp", "Escape", "Enter", "Tab"].includes(evt.key) &&
      !(onEnterKeypress && evt.key === "Enter")
    ) {
      inputRef.current?.blur();
    }
    if (onKeyDown) onKeyDown(evt);
  };

  const onPlusMinusClick = (type: "plus" | "minus") => () => {
    let intValue = parseInt(value || "0", 10);
    if (type === "plus" && intValue > -50) intValue--;
    if (type === "minus" && intValue < -1) intValue++;
    handleChange({target: {value: intValue.toString()}} as React.ChangeEvent<HTMLInputElement>);
  };

  // If it's multiline AND autosize, use TextareaAutosize, else use textarea
  const Textarea = autosize ? TextareaAutosize : "textarea";

  const actualInput = multiline ? (
    <Textarea
      autoComplete={autocomplete}
      autoCorrect={"off"}
      disabled={disabled}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      ref={inputRef as React.RefObject<HTMLTextAreaElement>}
      spellCheck={false}
      value={displayedValue}
      maxRows={autosize && maxrows ? maxrows : undefined}
      tabIndex={tabIndex}
    ></Textarea>
  ) : (
    <input
      autoComplete={autocomplete}
      autoCorrect={"off"}
      disabled={disabled}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      ref={inputRef as React.RefObject<HTMLInputElement>}
      spellCheck={false}
      type={type}
      value={displayedValue}
      tabIndex={tabIndex}
    />
  );

  return (
    <div className={classes}>
      {plusMinusButtons ? (
        <div className={styles.minus}>
          <RoundButton icon="minus" iconSize={21} onClick={onPlusMinusClick("minus")} enableCssStates />
        </div>
      ) : null}
      {shadowText ? (
        <>
          <div className={styles.absoluteWrapper}>{actualInput}</div>
          <span className={styles.shadowValue} style={{padding: inputPadding}}>
            {displayedValue}
          </span>
        </>
      ) : (
        actualInput
      )}

      {plusMinusButtons ? (
        <div className={styles.plus}>
          <RoundButton icon="plusLarger" iconSize={21} onClick={onPlusMinusClick("plus")} enableCssStates />
        </div>
      ) : null}
      {!!rightButtonIcon ? (
        <div className={styles.rightButtonWrapper}>
          <RoundButton
            icon={rightButtonIcon}
            mainColor="grey"
            rotate={rightButtonIcon === "arrow" ? 180 : 0}
            onClick={onRightButtonClick}
          />
        </div>
      ) : null}
    </div>
  );
});
