import {useAppDispatch, useAppSelector} from "@app/hooks";
import Button from "@components/Button";
import Dropdown from "@components/Dropdown";
import Popover from "@components/Popover";
import Tag from "@components/Tag";
import TemplateOptions from "@features/templates/TemplateOptions";
import {selectScenarioId, selectVersionLocked, setScenarioId} from "@shared/state/global/slice";
import {selectAllScenarios} from "@shared/state/scenarios/slice";
import clsx from "clsx";
import {useState} from "react";

import useStyles, {sharedValues} from "./styles.jss";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import type {DropdownItem} from "@components/Dropdown";
import RoundButton from "@components/RoundButton";
import {updateTemplatesViewState} from "@features/templates/state/slice";
import type {Scenario} from "@shared/types/db";
import CreateNewScenario from "../CreateScenario";

export interface ScenarioSelectorProps {
  width?: number;
  showDisplayOptions?: boolean;
}

// should only be visible if the direct parent of that component is hovered
const DropdownCogButton = ({scenario, onClick}: {scenario: Scenario; onClick: () => void}) => {
  const dispatch = useAppDispatch();

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    // e.preventDefault();
    e.stopPropagation();

    onClick();
  };

  return <RoundButton icon="cog" iconColor="orange" minimal enableCssStates onClick={handleClick} />;
};

const newScenarioKey = "::new";

export default function ScenarioSelector({
  width = sharedValues.scenarioDropdownDefaultWidth,
  showDisplayOptions = false,
}: ScenarioSelectorProps) {
  const styles = useStyles({showDisplayOptions});
  const scenarioId = useAppSelector(selectScenarioId);
  const scenarios = useAppSelector(selectAllScenarios);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [editedScenario, setEditedScenario] = useState<Scenario | null>(null);
  const versionLocked = useAppSelector(selectVersionLocked);

  const selectedScenario = scenarios.find((scenario) => scenario.id === scenarioId) as Scenario;

  const dropdownItems: DropdownItem[] = scenarios.map((scenario) => ({
    key: scenario.id,
    value: scenario.name,
    selected: scenarioId === scenario.id,
    elementRightOnHover: <DropdownCogButton scenario={scenario} onClick={() => setEditedScenario(scenario)} />,
  }));

  dropdownItems.push({
    key: newScenarioKey,
    value: "New Scenario...",
    selected: false,
    disabled: versionLocked,
    iconLeft: "plus",
    borderTop: true,
  });

  const handleScenarioChange = (item: DropdownItem) => {
    if (item.key === newScenarioKey) {
      dispatch(updateTemplatesViewState({createScenarioOpen: true}));
      return;
    }

    logUserEvent("scenario-selector-change", {from: selectedScenario.name, to: item.value});
    dispatch(setScenarioId(item.key));
  };

  const classes = clsx(styles.scenarioDropdownWrapper);

  return (
    <div className={classes}>
      <Dropdown
        items={dropdownItems}
        onSelect={handleScenarioChange}
        width={175}
        align="center"
        onClose={() => setIsOpen(false)}
        disabled={scenarios.length === 0 || editedScenario !== null}
      >
        <Tag
          active={isOpen}
          color="blue"
          text={selectedScenario.name}
          iconRight="dropdown"
          hover
          onClick={() => setIsOpen(!isOpen)}
        />
      </Dropdown>
      {showDisplayOptions ? (
        <Popover arrow disableSelection closeOnClick={false} content={<TemplateOptions />} width={400}>
          <Button iconRight="calendar" className={styles.iconButtonRight} color="lightOrange" minimal outline />
        </Popover>
      ) : null}
      {editedScenario ? (
        <CreateNewScenario onClose={() => setEditedScenario(null)} editedScenario={editedScenario} />
      ) : null}
    </div>
  );
}
