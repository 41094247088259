import type {TemplatesViewState} from "./slice";

export const initialState: TemplatesViewState = {
  activeCell: {},
  collapsedRows: [],
  templateId: null,
  draggingFrom: null,
  columnDateKeys: [],
  rowNameWidth: 250,
  otherUsersSelectedCells: [],
  highlightedDateKeys: [],
  cellBeingEdited: null,
  pendingDateKeys: [],
  draggingOverCols: [],
  cellWidth: null,
  createScenarioOpen: false,
  createVendorOpen: false,
  createScenarioProgress: null,
  sidebarOpen: false,
  dragDirection: null,
  blueRowsPulledFromWorker: [],
  loadingNewData: false,
  rowsForcedVisible: [],
};
