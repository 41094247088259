import {useAppDispatch, useAppSelector} from "@app/hooks";
import Dropdown from "@components/Dropdown/DropdownV2";
import FormElement from "@components/FormElement";
import Section from "@components/RightSidebar/Section";
import DatasourceSelector from "@features/shared-views/DatasourceSelector";
import {selectSelectedRow} from "@features/templates/state/selectors";
import id from "@shared/lib/id";
import {selectAllIntegrations, selectLoadingQboStatementRows, selectQboStatementRows} from "@state/integrations/slice";
import {makeSelectSanityChecksForRowId} from "@state/sanity-checks/selectors";
import {deleteSanityChecks, upsertSanityChecks} from "@state/sanity-checks/slice";
import {projKey} from "@state/utils";
import {useEffect, useMemo, useState} from "react";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import type {DropdownItem} from "@components/Dropdown";
import type {IntegrationDataQboStatementRow, QBOIntegration, SanityCheck, TemplateRow} from "@shared/types/db";

export default function SanityChecks() {
  const row = useAppSelector(selectSelectedRow);
  const integrations = useAppSelector(selectAllIntegrations);
  const dispatch = useAppDispatch();
  const loadingQboStatementRows = useAppSelector(selectLoadingQboStatementRows);
  const qboStatementRows = useAppSelector(selectQboStatementRows);
  const selectSanityChecksForRow = useMemo(makeSelectSanityChecksForRowId, []);
  const sanityChecks = useAppSelector((state) => selectSanityChecksForRow(state, row?.id ?? ""));

  const currentSanityCheck = sanityChecks?.[0];
  const currentSource = currentSanityCheck?.against_entity_identifier.includes("::")
    ? "qbo-statement-row"
    : "cloudberry-row";
  const [source, setSource] = useState<"cloudberry-row" | "qbo-statement-row">(currentSource);
  const rowId = row?.id ?? null;

  // Reset source if row id changes
  useEffect(() => {
    setSource(currentSource ?? "cloudberry-row");
  }, [rowId]);

  const qboIntegrations = integrations.filter(
    (integration): integration is QBOIntegration => integration.provider === "quickbooks-online",
  );

  if (!row || (row.type !== "account" && row.type !== "generic")) return null;

  const handleSourceChange = ({key}: DropdownItem) => {
    if (currentSanityCheck) dispatch(deleteSanityChecks([currentSanityCheck.id]));

    const source = key === "cloudberry-row" ? "cloudberry-row" : "qbo-statement-row";
    setSource(source);

    logUserEvent("sanity-check-source-change", {rowId: row.id, oldSource: currentSource, newSource: source});
  };

  const handleCompareToRowChange = (item: DropdownItem | TemplateRow | null) => {
    let key: string | null = "";
    if (!item) {
      key = null;
    } else {
      if ("id" in item) {
        key = item.id;
      } else {
        key = item.key;
      }
    }

    logUserEvent("sanity-check-row-change", {
      rowId: row.id,
      oldRow: currentSanityCheck?.against_entity_identifier ?? "None",
      newRow: key,
    });

    if (!key) {
      dispatch(deleteSanityChecks([currentSanityCheck.id]));
    } else {
      if (currentSanityCheck?.against_entity_identifier === key) return;

      const sanityCheckToUpsert: SanityCheck = {
        id: currentSanityCheck?.id ?? id(),
        scope: "row",
        type: source,
        check_entity_identifier: row.id,
        against_entity_identifier: key,
        passed: true,
      };

      dispatch(upsertSanityChecks([sanityCheckToUpsert]));
    }
  };

  const selectedSourceInDropdown =
    source === "qbo-statement-row"
      ? (currentSanityCheck?.against_entity_identifier.split("::")[0] ?? qboIntegrations[0].id)
      : "cloudberry-row";

  return (
    <Section title="Sanity Checks">
      <FormElement label="Comparison Source" tooltip="Templates::Sidebar::Settings::SanityChecks::ComparisonSource">
        <Dropdown
          items={getSourceDropdownItems(qboIntegrations)}
          selectedKey={selectedSourceInDropdown}
          onSelect={handleSourceChange}
        />
      </FormElement>
      <FormElement label="Compare To Row" tooltip="Templates::Sidebar::Settings::SanityChecks::CompareToRow">
        {source === "cloudberry-row" ? (
          <DatasourceSelector
            selectedKey={currentSanityCheck?.against_entity_identifier ?? null}
            onDatasourceSelect={handleCompareToRowChange}
            placeholder="None"
            allowEmpty
          />
        ) : (
          <Dropdown
            items={
              loadingQboStatementRows ? [{key: null, value: "Loading..."}] : getQboRowsDropdownItems(qboStatementRows)
            }
            disabled={loadingQboStatementRows || !qboStatementRows.length}
            selectedKey={currentSanityCheck?.against_entity_identifier ?? null}
            onSelect={handleCompareToRowChange}
            showSearch
            portal
          />
        )}
      </FormElement>
    </Section>
  );
}

function getSourceDropdownItems(integrations: QBOIntegration[]): DropdownItem[] {
  const integrationItems = integrations.map((integration) => ({
    key: integration.id,
    value: `QuickBooks Online`,
    subtext: integration.data?.companyName ?? "",
    iconRight: "qbLogo",
  })) satisfies DropdownItem[];

  const items: DropdownItem[] = [
    {
      key: "cloudberry-row",
      value: `Cloudberry`,
      iconRight: "cbLogo",
      subtext: "Cloudberry",
    },
    ...integrationItems,
  ];

  return items;
}

const statementShortNameMap: Record<string, string> = {
  pnl: "Profit & Loss",
  bs: "Balance Sheet",
  cfs: "Cash Flow Statement",
};

function getQboRowsDropdownItems(rows: IntegrationDataQboStatementRow[]): DropdownItem[] {
  if (!rows.length) return [{key: null, value: "No rows found - please sync QuickBooks data."}];
  return [
    {key: null, value: "None"},
    ...rows.map((row) => ({
      key: projKey(row.integration_id, row.data.statement, row.data.account),
      value: row.data.account,
      rightText: statementShortNameMap[row.data.statement] ?? row.data.statement,
      indentation: row.data.depth,
    })),
  ];
}
