import LazyFallback from "@components/LazyFallback";
import PageNotFound from "@features/shared-views/ErrorPages/PageNotFound";
import {lazy, Suspense} from "react";
import {createRoutesFromChildren, Navigate, Route, Routes} from "react-router-dom";

import {WorksheetsIndexRedirect} from "./features/templates";

const Admin = lazy(() => import("./features/admin"));
const Templates = lazy(() => import("./features/templates"));
const HiringPlan = lazy(() => import("./features/hiring-plan"));
const Settings = lazy(() => import("./features/settings"));
const Reporting = lazy(() => import("./features/reporting"));
const Vendors = lazy(() => import("./features/vendors"));
const Inbox = lazy(() => import("./features/inbox"));
const Login = lazy(() => import("./features/auth/Login"));
const AcceptInvite = lazy(() => import("./features/auth/AcceptInvite"));

export const routesList = [
  {path: "/admin/*", element: <Admin />},
  {path: "/financial-statements/*", element: <Templates />},
  {path: "/templates/:templateName", element: <Templates />},
  {path: "/worksheets/:templateName", element: <Templates />},
  {path: "/templates", element: <WorksheetsIndexRedirect />},
  {path: "/worksheets", element: <WorksheetsIndexRedirect />},
  {path: "/settings/*", element: <Settings />},
  {path: "/hiring-plan/*", element: <HiringPlan />},
  {path: "/reports/:reportName", element: <Reporting />},
  {path: "/reports/*", element: <Reporting />},
  {path: "/reports", element: <Reporting />},
  {path: "/vendors", element: <Vendors />},
  {path: "/reporting", element: <Reporting />},
  {path: "/inbox/*", element: <Inbox />},
  {path: "/login", element: <Navigate to="/reporting" replace />},
  {path: "/", element: <Navigate to="/reporting" replace />},
  {path: "/*", element: <PageNotFound />},
];

const routeElements = routesList.map((routeItem) => (
  <Route key={routeItem.path} path={routeItem.path} element={routeItem.element} />
));

export const routes = createRoutesFromChildren(routeElements);

export function CbRouter() {
  return (
    <Suspense fallback={<LazyFallback />}>
      <Routes>{routeElements}</Routes>
    </Suspense>
  );
}

export function AuthRouter() {
  return (
    <Suspense fallback="Loading data...">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/accept-invite" element={<AcceptInvite />} />
        <Route path="/*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Suspense>
  );
}
