import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import Button from "@components/Button/V2";
import SVGIcon from "@components/SVGIcon";
import TextInput from "@components/TextInput";
import id from "@shared/lib/id";
import {api as adminAPI, selectAllInvitesByUserId} from "@shared/state/admin/slice";
import {api as companiesAPI, selectAllCompanies} from "@shared/state/companies/slice";
import {selectAllUsersByCompanyId} from "@state/admin/slice";
import dayjs from "dayjs";
import {useEffect, useState} from "react";

import ActionConfirmation from "../shared-views/ActionConfirmation";
import useStyles from "./styles.jss";

import Dropdown from "@components/Dropdown/DropdownV2";
import type {Company, User, UserInvite} from "@shared/types/db";

export interface CompaniesProps {}

export default function Companies({}: CompaniesProps) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();
  const companies = useAppSelector(selectAllCompanies);
  const allUsers = useAppSelector(selectAllUsersByCompanyId);
  const invitesByUserId = useAppSelector(selectAllInvitesByUserId);
  const [showUsersForCompany, setShowUsersForCompany] = useState<string | null>(null);
  const [creatingCompany, setCreatingCompany] = useState(false);
  const [companyBeingEdited, setCompanyBeingEdited] = useState<Company | null>(null);
  const [slugTaken, setSlugTaken] = useState(false);

  useEffect(() => {
    dispatch(companiesAPI.fetchAll(null));
    dispatch(adminAPI.fetchAllUsers(null));
    dispatch(adminAPI.fetchAllInvites(null));
  }, [dispatch]);

  if (!allUsers || !companies || !invitesByUserId) return <div>Loading...</div>;

  const handleResetClick = (companyName: string) => () => {
    dispatch(
      askForConfirmation({
        onConfirm: () => {
          dispatch(companiesAPI.reset(companyName));
        },
        backgroundImage: "/assets/images/ui/delete-modal-bg.svg",
        mainColor: "orange",
        actionButtonText: "Reset all data",
        text: `Are you sure you want to reset the company <b>${
          companies.find((company) => company.name === companyName)?.display_name
        }</b>?<br /><br />All this company data will be erased from the database and reset to a clean state.`,
      }),
    );
  };

  const handleEditCompany = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!companyBeingEdited) return;
    const newCompany = {...companyBeingEdited};
    const key = evt.target.name as keyof Company;
    const value = key === "name" ? evt.target.value.toLowerCase() : evt.target.value;
    newCompany[key] = value;
    setCompanyBeingEdited(newCompany);

    if (key === "name") {
      setSlugTaken(companies.some((company) => company.name.toLowerCase() === evt.target.value.trim().toLowerCase()));
    }
  };

  const handleCreateCompany = async () => {
    if (!companyBeingEdited) return;
    setCreatingCompany(false);
    await dispatch(companiesAPI.create(companyBeingEdited));
    dispatch(companiesAPI.fetchAll(null));
  };

  const handleStartCreatingCompany = () => {
    setCreatingCompany(true);
    setCompanyBeingEdited({
      id: id(),
      name: "",
      display_name: "",
    });
  };

  return (
    <>
      <ActionConfirmation />
      {creatingCompany ? (
        <div className={styles.inlineEditForm}>
          <div>
            <TextInput
              placeholder="Slug"
              name="name"
              onChange={handleEditCompany}
              value={companyBeingEdited?.name ?? ""}
            />
          </div>
          <div>
            <TextInput
              placeholder="Display name"
              name="display_name"
              onChange={handleEditCompany}
              value={companyBeingEdited?.display_name ?? ""}
            />
          </div>
          <div>
            <Button text="Save" color="green" onClick={handleCreateCompany} disabled={slugTaken} />
          </div>
        </div>
      ) : (
        <Button
          text="Create a new company"
          color="green"
          iconLeft="plusLarger"
          size="small"
          onClick={handleStartCreatingCompany}
        />
      )}

      {companies.map((company) => (
        <div key={company.id} className={styles.company}>
          <div className={styles.companyFirstRow}>
            <div className={styles.name}>{company.display_name}</div>
            <Button
              text={showUsersForCompany === company.name ? "Hide users" : "Show users"}
              color="green"
              onClick={() => setShowUsersForCompany(showUsersForCompany === company.name ? null : company.name)}
              size="xsmall"
            />
            <Button text="Reset" color="orange" onClick={handleResetClick(company.name)} size="xsmall" />
            <Button text="Delete" color="red" iconLeft="trash" size="xsmall" />
          </div>
          {showUsersForCompany === company.name ? (
            <CompanyUsers company={company} users={allUsers[company.id] ?? []} invitesByUserId={invitesByUserId} />
          ) : null}
        </div>
      ))}
    </>
  );
}

function CompanyUsers({
  company,
  users,
  invitesByUserId,
}: {
  company: Company;
  users: User[];
  invitesByUserId: Record<string, UserInvite[]>;
}) {
  const styles = useStyles();
  const [creatingUser, setCreatingUser] = useState(false);
  const [userBeingEdited, setUserBeingEdited] = useState<User | null>(null);
  const [emailTaken, setEmailTaken] = useState(false);
  const dispatch = useAppThunkDispatch();

  const handleUserSave = async () => {
    if (!userBeingEdited) return;
    setCreatingUser(false);
    await dispatch(adminAPI.createUser(userBeingEdited));
    dispatch(adminAPI.fetchAllUsers(null));
    dispatch(adminAPI.fetchAllInvites(null));
  };

  const handleStartCreatingUser = () => {
    setCreatingUser(true);

    const newUser: User = {
      id: id(),
      name: "",
      email: "",
      company_id: company.id,
      auth: {local: {password: ""}},
    };

    setUserBeingEdited(newUser);
  };

  const handleUserChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!userBeingEdited) return;
    const newUser = {...userBeingEdited};
    const key = evt.target.name as keyof User;
    newUser[key] = evt.target.value;
    setUserBeingEdited(newUser);

    if (key === "email") {
      setEmailTaken(users.some((user) => user.email === evt.target.value.trim()));
    }
  };

  return (
    <div className={styles.companyUsers}>
      {creatingUser ? (
        <div className={styles.inlineEditForm}>
          <div>
            <TextInput placeholder="Name" name="name" onChange={handleUserChange} value={userBeingEdited?.name ?? ""} />
          </div>
          <div>
            <TextInput
              placeholder="Email"
              name="email"
              onChange={handleUserChange}
              value={userBeingEdited?.email ?? ""}
            />
          </div>
          <div>
            <Button text="Save" color="green" onClick={handleUserSave} disabled={emailTaken} />
          </div>
        </div>
      ) : (
        <Button
          text="Create a new user (and invite)"
          color="green"
          size="small"
          iconLeft="plusLarger"
          onClick={handleStartCreatingUser}
        />
      )}
      <table>
        <thead>
          <tr>
            <th className={styles.user}>
              <SVGIcon name="person" />
              <span className={styles.userName}>Name</span>
            </th>
            <th>
              <span>Email</span>
            </th>
            <th>Role</th>
            <th>Invited</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserItem key={user.id} user={user} invites={invitesByUserId[user.id] ?? []} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function UserItem({user, invites}: {user: User; invites: UserInvite[]}) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const handleUserDelete = (userId: string) => {
    dispatch(
      askForConfirmation({
        onConfirm: async () => {
          await dispatch(adminAPI.deleteUser({userId}));
          dispatch(adminAPI.fetchAllUsers(null));
        },
        backgroundImage: "/assets/images/ui/delete-modal-bg.svg",
        mainColor: "red",
        actionButtonText: "Delete user",
        text: `Are you sure you want to delete this user? This will remove the user from the company and revoke their access to the system.`,
      }),
    );
  };

  const handleUpdateRole = (role: "superuser" | "user") => {
    dispatch(adminAPI.updateUser({...user, role}));
  };

  const handleCopyInviteLink = (invite: UserInvite) => {
    navigator.clipboard.writeText(`${window.location.origin}/accept-invite?code=${invite.code}`);
  };

  return (
    <tr>
      <td>
        <div className={styles.user}>
          <SVGIcon name="person" />
          <span className={styles.userName}>{user.name}</span>
        </div>
      </td>
      <td>
        <span className={styles.email}>{user.email}</span>
      </td>
      <td>
        <Dropdown
          items={[
            {key: "superuser", value: "Superuser"},
            {key: "user", value: "User"},
          ]}
          selectedKey={user.role ?? "user"}
          onSelect={({key}) => handleUpdateRole(key as "superuser" | "user")}
          renderAsChild
        />
      </td>
      <td>
        <div>
          {invites.length ? (
            <div>
              <span>
                Yes (
                {invites[0].accepted_at
                  ? `accepted on ${dayjs(invites[0].accepted_at).format("MMM D, YYYY")}`
                  : `pending, expires on ${dayjs(invites[0].expired_at).format("MMM D, YYYY")}`}
                )
              </span>
            </div>
          ) : (
            <span>No</span>
          )}
        </div>
      </td>
      <td>
        <div className={styles.userButtons}>
          {invites.length && invites[0].accepted_at === null ? (
            <Button
              text="Copy invite"
              color="green"
              size="xsmall"
              iconLeft="duplicate"
              onClick={() => handleCopyInviteLink(invites[0])}
            />
          ) : null}
          <Button
            text="Delete"
            color="red"
            size="xsmall"
            iconLeft={"trash"}
            onClick={() => handleUserDelete(user.id)}
          />
        </div>
      </td>
    </tr>
  );
}
