import {resolveDaterange} from "@shared/data-functions/formula/date-range";
import {getRefsFromFormula} from "@shared/data-functions/formula/formula-utilities";
import {projKeyOmitNulls} from "@shared/state/utils";
import Rfdc from "rfdc";

import {
  getDatasourceDiff,
  getEmptyDatasourceDiff,
  insertDatasources,
  mergeDatasourceDiffObjects,
} from "./datasource-utilities";
import {addDeltaMonthsToDateKey, getAllDateKeysBetween} from "./date-utilities";
import {mapEntitiesToIds} from "./entity-functions";
import id from "./id";
import {findAllTransactionIdsToClearForLMOAChange} from "./template-utilities";

import type {EntityId} from "@reduxjs/toolkit";
import type {TemplateRowsState, TemplatesState} from "@shared/state/entity-adapters";
import type {Datasource, FormulaReference} from "@shared/types/datasources";
import type {AccountTemplateRow, Template, TemplateOptions, TemplateOrdering, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";
import type {GlobalForecastDates} from "./datasource-utilities";

const rfdc = Rfdc();

export function addItemInTemplateOrdering(
  operation: "move" | "add",
  ordering: TemplateOrdering[],
  parentId: string,
  newItemId: string,
  removedItem: TemplateOrdering | null,
  orderAlphabetically: boolean,
  rowEntities: Record<string, TemplateRow>,
): TemplateOrdering[];
export function addItemInTemplateOrdering(
  operation: "move" | "add",
  ordering: TemplateOrdering[],
  parentId: string,
  newItemId: string,
  removedItem: TemplateOrdering | null,
): TemplateOrdering[];
export function addItemInTemplateOrdering(
  operation: "move" | "add",
  ordering: TemplateOrdering[],
  parentId: string,
  newItemId: string,
  removedItem: TemplateOrdering | null,
  orderAlphabetically?: boolean,
  rowEntities?: Record<string, TemplateRow>,
) {
  function recursive(ordering: TemplateOrdering[]) {
    for (const orderingItem of ordering) {
      if (operation === "move" && !removedItem && orderingItem.children?.length) {
        let indexInChildren = orderingItem.children.findIndex((item) => item.rowId === newItemId);
        while (indexInChildren > -1) {
          removedItem = orderingItem.children.splice(indexInChildren, 1)[0];
          indexInChildren = orderingItem.children.findIndex((item) => item.rowId === newItemId);
          // break; // Maybe not good since it seems like it leaves an extra entry behind in some cases
        }
        recursive(orderingItem.children);
      } else {
        if (orderingItem.rowId === parentId) {
          const children = orderingItem.children || [];
          children.push(removedItem || {rowId: newItemId});
          if (orderAlphabetically && rowEntities) {
            orderingItem.children = children.sort((a, b) =>
              rowEntities[a.rowId]?.display_name.localeCompare(rowEntities[b.rowId]?.display_name),
            );
          } else {
            orderingItem.children = children;
          }
          if (operation === "add") return true;
        } else if (orderingItem.children?.length) {
          const hasUpdatedOrdering = recursive(orderingItem.children);
          if (operation === "add" && hasUpdatedOrdering) return ordering;
        }
      }
    }
  }
  if (operation === "move" && !removedItem) recursive(ordering);
  recursive(ordering);
  return ordering;
}

export function getMergedTagsMapping(row: TemplateRow) {
  const aliases: Record<string, string> = {};
  if (row.type === "hiring-plan" && row.options.fc_name) aliases.compensation_name = row.options.fc_name;
  return {...row.options, ...row.tags, col_name: row.name, ...aliases} as Record<string, string>;
}

export function getMergedTags(row: TemplateRow) {
  return Object.entries(getMergedTagsMapping(row)).map(([key, value]) => `${key}::${value}`);
}

export type InsertFormulaBaseParams = {
  row: {id: string; name: string};
  scenarioId: string;
  templateOptions: TemplateOptions;
  datasources: Datasource[];
  departmentId?: string | null;
  vendor?: string | null;
};

export function insertFormulaForDates({
  dateKeyToFormulaMapping,
  row,
  scenarioId,
  templateOptions,
  datasources,
  departmentId,
  vendor,
}: InsertFormulaBaseParams & {
  dateKeyToFormulaMapping: Record<string, string | null>;
}) {
  // console.log(
  //   JSON.stringify({
  //     formulaMapping,
  //     formulaReferencesMapping,
  //     row,
  //     scenarioId,
  //     templateOptions,
  //   }),
  // );
  const _formulaReferencesMapping: Record<string, FormulaReference[]> = {};
  const ranges: {start: string; end: string; formula: string | null; references: FormulaReference[]}[] = [];
  let prevFormula: string | null = null;
  for (const [dateKey, formula] of Object.entries(dateKeyToFormulaMapping).sort((a, b) => a[0].localeCompare(b[0]))) {
    if (formula) _formulaReferencesMapping[formula] ||= getRefsFromFormula(formula, row.name);
    if (ranges.length && formula === prevFormula) {
      ranges.at(-1)!.end = dateKey;
    } else {
      ranges.push({
        start: dateKey,
        end: dateKey,
        formula,
        references: formula ? _formulaReferencesMapping[formula] : [],
      });
      prevFormula = formula;
    }
  }
  const datasourcesToAdd: Datasource[] = [];
  for (const {start, end, formula, references} of ranges) {
    const newDatasource = {
      id: id(),
      row_id: row.id,
      department_id: departmentId ?? null,
      scenario_id: scenarioId,
      type: "formula",
      start,
      end,
      options: {
        formula,
        references,
      },
    } as Datasource;
    if (vendor) newDatasource.dimensions = {vendor};
    datasourcesToAdd.push(newDatasource);
  }

  const result = insertDatasources({
    existingDatasources: datasources,
    datasourcesToAdd,
    forecastDates: templateOptions,
  });

  return result;

  // console.log(JSON.stringify(result));
}

export function generateRangeStrFromDatasource(
  datasource: Datasource,
  templateOptions: GlobalForecastDates,
  dayLevel?: boolean,
) {
  let start = datasource.start || templateOptions.start;
  let end = datasource.end || templateOptions.end;

  if (!dayLevel) {
    start = start.slice(0, 7);
    end = end.slice(0, 7);
  }

  if (start === "Invalid") return null;
  // Fix for problematic integration range formulas that were set from start: null to end: -1
  if (datasource.type === "integration") {
    if (datasource.start && datasource.start[0] === "-" && !datasource.start.includes("a"))
      start = `${datasource.start}a`;
    if (datasource.end && datasource.end[0] === "-" && !datasource.end.includes("a")) end = `${datasource.end}a`;
  }

  let rangeStr = `${start}:${end}`;
  rangeStr = rangeStr.replace(/-00/g, "-12");

  return {str: rangeStr, start, end};
}

export function getResolvedDateRangeFromDatasource(datasource: Datasource, templateOptions: GlobalForecastDates) {
  const rangeStr = generateRangeStrFromDatasource(datasource, templateOptions);
  if (!rangeStr) return null;

  const resolvedDateRange = resolveDaterange(
    rangeStr.str.replace("Invalid", templateOptions.end),
    null,
    templateOptions.lastMonthOfActuals,
  );

  return resolvedDateRange;
}

export function moveLastMonthOfActuals({
  template,
  state,
  newLMOA,
}: {
  template: Template;
  state: RootState;
  newLMOA: string;
}) {
  //   console.log(
  //     JSON.stringify({
  //       template,
  //       rowsInTemplate,
  //       newLMOA,
  //     }),
  //   );

  const currentLMOA = template.options.lastMonthOfActuals;
  const direction: "FORWARD" | "BACKWARD" = currentLMOA < newLMOA ? "FORWARD" : "BACKWARD";

  const rangeToClear = {
    from: direction === "FORWARD" ? addDeltaMonthsToDateKey(currentLMOA, 1) : addDeltaMonthsToDateKey(newLMOA, 1),
    to: direction === "FORWARD" ? newLMOA : currentLMOA,
  };

  const monthsToClear = getAllDateKeysBetween(rangeToClear.from, rangeToClear.to);

  const rowIdsInTemplate = state.templateRows.idsByTemplateId[template.id] ?? [];

  const {cbTxIds, datasourceDiff} = findAllTransactionIdsToClearForLMOAChange(
    state,
    monthsToClear,
    [template.id],
    getEmptyDatasourceDiff(),
  );

  let mergedDatasourceDiff = datasourceDiff;

  const datasourcesInTemplateByProjKey: Record<string, Datasource[]> = {};
  for (const rowId of rowIdsInTemplate) {
    const datasourcesInRow: Datasource[] = [];
    const rowDatasourceIds = state.datasources.idsByRowId[rowId];
    if (rowDatasourceIds?.length)
      datasourcesInRow.push(...mapEntitiesToIds(state.datasources.entities, rowDatasourceIds));

    for (const datasource of datasourcesInRow) {
      const datasourceProjKey = projKeyOmitNulls(
        datasource.row_id,
        datasource.scenario_id,
        datasource.department_id,
        datasource.dimensions?.vendor?.toLowerCase(),
      );

      datasourcesInTemplateByProjKey[datasourceProjKey] ||= [];
      datasourcesInTemplateByProjKey[datasourceProjKey].push(datasource);
    }
  }

  for (const datasources of Object.values(datasourcesInTemplateByProjKey)) {
    let rowId: EntityId | null = datasources[0].row_id;

    let clearMonths = true;
    for (const datasource of datasources) {
      const resolvedDateRange = getResolvedDateRangeFromDatasource(datasource, template.options);
      if (!resolvedDateRange) continue;
      if (
        resolvedDateRange?.start.slice(0, 7) < monthsToClear[0].slice(0, 7) &&
        resolvedDateRange?.end.slice(0, 7) > monthsToClear[0].slice(0, 7)
      ) {
        clearMonths = false;
      }
    }

    let rowName = state.templateRows.entities[rowId ?? ""]?.name;
    const newTemplateOptions = {...template.options, lastMonthOfActuals: newLMOA};

    const result = insertDatasources({
      existingDatasources: datasources,
      datasourcesToAdd: [],
      forecastDates: newTemplateOptions,
      // method: "replace",
      dateKeysToClearAfterLmoaMove: clearMonths ? monthsToClear : undefined,
      // debug: rowName === "bank_fees",
    });

    const datasourceDiff = getDatasourceDiff(
      datasources,
      result.datasources,
      state,
      newTemplateOptions,
      undefined,
      monthsToClear,
    );

    mergedDatasourceDiff = mergeDatasourceDiffObjects(mergedDatasourceDiff, datasourceDiff);
  }

  return {datasourceDiff: mergedDatasourceDiff, txIdsToDelete: cbTxIds};
}

type RowIsHiddenParams = {
  templateRowsState: TemplateRowsState;
  templatesState: TemplatesState;
  monthlyCache: Record<string, number>;
  rowId: string;
  templateId: string | null;
  scenarioId: string | null;
};

export function isBalance(row?: TemplateRow): row is AccountTemplateRow {
  return !!(row?.type === "account" && row.options.balance);
}

export function getForecastTypeForRow(
  row: TemplateRow,
  departmentId: EntityId | null,
): "row" | "department" | "vendor" {
  let forecastType: "row" | "department" | "vendor" = "row";
  if (row.type === "account" && row.options.forecastType === "department") {
    forecastType = "department";
    if (departmentId && row.options.vendorLevelDepartments?.includes(departmentId.toString())) {
      forecastType = "vendor";
    }
  }

  return forecastType;
}
